import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import clsx from 'clsx';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';

import notfound_light from '../assets/StreamNotFound_light.png';
import notfound_dark from '../assets/StreamNotFound_dark.png';

import orangeLogo from '../assets/orange-nsc.png';
import whiteLogo from '../assets/nsc.png'

import { makeStyles } from '@mui/styles';
import { useTheme } from '@emotion/react';
import { Grid, Typography, Button, AppBar, Toolbar, Stack, Box } from '@mui/material';
import { AccountCircle, AccountCircleOutlined, AddCircleOutline, HeadsetMicOutlined, Login, LoginOutlined, } from '@mui/icons-material';

import Loading from '../components/global/Loading';
import Player from '../components/player/Player';
import SnackbarMessage from '../components/global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
    image: {
        width: 600,
        maxWidth: '100%',
        margin: '0 auto',
        display: 'block',
        marginBottom: theme.spacing(2)
    },
    item: {
        textAlign: 'center',
    },
    button: {
        margin: `${theme.spacing(1, 1, 0, 0)} !important`
    },
    notAuthed: {
        padding: theme.spacing(3),
        paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(3)})`
    },
}));

export default function Playout(props) {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();
    let { id } = useParams();
    const [searchParams] = useSearchParams();

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [isExternal] = useState(props.isExternal);

    const customServerDomain = searchParams.get("general.serverDomain");
    const paramToken = searchParams.get("security.jwtoken") || searchParams.get("security.token");
    const token = sessionStorage.getItem(ls.H5LIVE_HASH_SECURE) ? JSON.parse(sessionStorage.getItem(ls.H5LIVE_HASH_SECURE)) : null;
    const orga = sessionStorage.getItem(ls.BINTU_ORGA) ? JSON.parse(sessionStorage.getItem(ls.BINTU_ORGA)) : null;
    const orgaSecured = orga?.secure;

    const h5liveToken = paramToken ? paramToken : (orgaSecured ? token : null)

    const [loading, setLoading] = useState(true);
    const [stream, setStream] = useState(false);

    document.title = "nanoStream H5Live Playout | nanoStream Cloud Dashboard";

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') return;
        setShowError(false);
    }

    const redirect = (link) => () => { navigate(link); }

    const getStreams = () => {

        let data = { streamid: id }

        bintu.getStreamById(data)
            .then((response) => {
                if (response.success) {
                    setStream(response.data);
                    setLoading(false);
                }
            }).catch((error) => {
                setStream(false);
                setLoading(false);
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    useEffect(() => { if (!stream) { getStreams(); } }, [])

    return (
        <Fragment>
            <SnackbarMessage open={showError} close={handleCloseError} type={"error"}>
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            {
                isExternal && !sessionStorage.getItem(ls.BINTU_ORGA)
                &&
                <AppBar
                    position="fixed"
                    elevation={0}
                    sx={{
                        background: '#ed7d0e',
                    }}
                >
                    <Toolbar sx={{ zIndex: 2147483648 }}>
                        <Stack direction="row" useFlexGap sx={{ width: '100%' }} spacing={1} alignItems={'center'} justifyContent={"space-between"}>
                            <Box
                                onClick={redirect(link.AUTH)}
                                component={"img"} sx={{ height: 30, cursor: 'pointer' }} src={theme.palette.mode === 'light' ? whiteLogo : orangeLogo}
                            />
                            <Button
                                startIcon={<AccountCircleOutlined />}
                                size="small" variant="outlined" onClick={redirect(link.AUTH)}
                                sx={{ color: theme.palette.common.white, borderColor: theme.palette.common.white }}
                            >
                                Login
                            </Button>
                        </Stack>
                    </Toolbar>
                </AppBar>
            }
            <Grid container className={clsx(classes.root, { [classes.notAuthed]: isExternal && !sessionStorage.getItem(ls.BINTU_ORGA) })}>
                {
                    loading
                        ? <Loading />
                        : <Fragment>
                            {
                                stream
                                    ?
                                    <Grid item xs={12} m={props.isExternal ? "auto" : 0} mb={4}>
                                        <Player
                                            isExternal={isExternal}
                                            stream={stream}
                                            token={h5liveToken}
                                            customServerDomain={customServerDomain}
                                        />
                                    </Grid>
                                    :
                                    <Grid item xs={12} className={classes.item}>
                                        <img alt="not-found" className={classes.image} src={theme.palette.mode === 'light' ? notfound_light : notfound_dark} />
                                        <Typography variant="h5" color="primary" >
                                            Oops, we could not find the stream you are looking for. <br />
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary">
                                            If you think this was a mistake, contact our support.
                                        </Typography>
                                        <div className={classes.buttonWrapper}>
                                            {
                                                isExternal
                                                    ?

                                                    <Button
                                                        className={classes.button}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={redirect(link.AUTH)}
                                                        startIcon={<Login />}
                                                    >
                                                        Authenticate
                                                    </Button>
                                                    :
                                                    <Button
                                                        className={classes.button}
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={redirect(link.CREATE_STREAM)}
                                                        startIcon={<AddCircleOutline />}
                                                    >
                                                        Create new stream
                                                    </Button>
                                            }
                                            <Button
                                                className={classes.button}
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => window.open(link.CONTACT, "_blank")}
                                                startIcon={<HeadsetMicOutlined />}
                                            >
                                                Talk to our support
                                            </Button>
                                        </div>
                                    </Grid>
                            }
                        </Fragment>
                }
            </Grid >
        </Fragment >
    )
}
