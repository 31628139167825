import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Grid, Link, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { CONTACT, DASHBOARD } from '../../utils/helper/link-config';
import { Dashboard, DashboardOutlined, ExitToApp, HeadsetMicOutlined, OpenInNew, ReadMore } from '@mui/icons-material';

export default function NoAccess(props) {
    const theme = useTheme();
    const { content } = props;
    const navigate = useNavigate();

    return (
        <Grid container sx={{ textAlign: 'center' }}>
            <Grid item xs={12}>
                <Typography variant="h4" color="primary">
                    Access Not Allowed
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    It looks like you do not have the necessary permissions to access <b>{content}</b>. <br />
                    You can learn more about user roles in nanoStream Cloud in our documentation.
                </Typography>
                <Stack
                    useFlexGap spacing={2} sx={{ mt: 1 }}
                    direction="row" justifyContent={"center"}
                >
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(DASHBOARD)}
                        startIcon={<ExitToApp />}
                    >
                        Go to dashboard
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary" target="_blank"
                        href="https://docs.nanocosmos.de/docs/user_roles#user-roles-in-nanostream-cloud"
                        startIcon={<OpenInNew />}
                    >
                        User Roles Documentation
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => window.open(CONTACT, "_blank")}
                        startIcon={<HeadsetMicOutlined />}
                    >
                        Talk to our support
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12} mt={2}>
                <Typography variant="body1" color="textSecondary">
                    <i>Please contact your system administrator for further assistance.</i>
                </Typography>
            </Grid>
        </Grid>
    )
}
