import React, { Fragment, useEffect, useState } from 'react';

import { TextField, Grid, FormControl, Select, MenuItem, Box, useTheme, Stack, FormControlLabel, Checkbox, Typography, Collapse, FormGroup, FormLabel, Divider, Tabs, Tab, Chip, alpha, Alert } from '@mui/material';

import SectionContainer from '../global/SectionContainer';
import { BINTU_ORGA } from '../../utils/helper/ls-vars';
import { LIVE_PROCESSING } from '../../utils/helper/nanostream-cloud';
import { AddCircle, AddTask, CheckCircle, CircleOutlined, Error, FmdBad, LockOpen, TaskAltOutlined } from '@mui/icons-material';
import { TabContext, TabList } from '@mui/lab';

const TabContent = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Box
            hidden={value !== index}
            {...other}
        >
            {value === index && <Fragment>{children}</Fragment>}
        </Box>
    );
}

export default function AddLiveProcessing(props) {
    const theme = useTheme();
    const { updateLiveProcessing, profiles } = props;
    const orga = sessionStorage.getItem(BINTU_ORGA) ? JSON.parse(sessionStorage.getItem(BINTU_ORGA)) : null;
    const isSecure = orga?.secure;
    const allowedOperations = orga?.allowedOpcodes ? orga.allowedOpcodes : [];

    const [tabIndex, setTabIndex] = useState(0);
    const [streamCount, setStreamCount] = useState(profiles.length + 1);
    const [options, setOptions] = useState([]);

    const [checked, setChecked] = useState([]);
    const [selectedStreams, setSelectedStreams] = useState([]);

    const handleTabbing = (event, newValue) => { setTabIndex(newValue); };

    const handleCheckProcess = (id, streamIndex) => () => {
        setChecked(prevChecked => {
            const updatedChecked = [...prevChecked];
            updatedChecked[streamIndex] = updatedChecked[streamIndex] || [];

            updatedChecked[streamIndex] = updatedChecked[streamIndex].includes(id)
                ? updatedChecked[streamIndex].filter(e => e !== id)
                : [...updatedChecked[streamIndex], id];

            return updatedChecked;
        });

        toggleStreamSelection(id, streamIndex)();
    };

    const toggleStreamSelection = (opcodeId, streamIndex) => () => {
        setSelectedStreams((prev) => {
            const streams = prev[opcodeId] || [];
            const updatedStreams = streams.includes(streamIndex)
                ? streams.filter(s => s !== streamIndex)
                : [...streams, streamIndex];
            return { ...prev, [opcodeId]: updatedStreams };
        });
    };

    const handleSelectEntireStream = (opcodeId, dataId, streamIndex, min) => (e) => {
        const isChecked = e.target.checked;

        setOptions(prevOptions =>
            prevOptions.map((streamOptions, index) =>
                index === streamIndex
                    ? streamOptions.map(opt =>
                        opt.id === opcodeId
                            ? { ...opt, [dataId]: isChecked ? null : min }
                            : opt
                    )
                    : streamOptions
            )
        );
    };

    const handleUpdate = (opcodeId, dataId, streamIndex) => (e) => {
        const value = e.target.value.trim();
        setOptions(prev =>
            prev.map((streamOptions, index) =>
                index === streamIndex
                    ? streamOptions.map(opt =>
                        opt.id === opcodeId
                            ? { ...opt, [dataId]: value }
                            : opt
                    )
                    : streamOptions
            )
        );
    };

    const handleBlur = (opcodeId, dataId, min, max, defaultValue, streamIndex) => (e) => {
        const value = e.target.value.trim();
        const parsedValue = parseInt(value, 10);
        const isValid = !isNaN(parsedValue) && parsedValue >= min && (max === undefined || parsedValue <= max);

        setOptions(prevOptions =>
            prevOptions.map((streamOptions, index) =>
                index === streamIndex
                    ? streamOptions.map(opt =>
                        opt.id === opcodeId
                            ? { ...opt, [dataId]: isValid ? parsedValue : defaultValue }
                            : opt
                    )
                    : streamOptions
            )
        );
    };

    const createOpcodes = () => {
        const streamOpcodes = {};

        Object.keys(selectedStreams).forEach((opcodeId) => {
            selectedStreams[opcodeId].forEach((streamIndex) => {
                if (!checked[streamIndex]?.includes(opcodeId)) return;

                const option = options[streamIndex]?.find(opt => opt.id === opcodeId);

                if (option) {
                    if (!streamOpcodes[streamIndex]) {
                        streamOpcodes[streamIndex] = [];
                    }
                    streamOpcodes[streamIndex].push({ ...option });
                }
            });
        });

        return Object.keys(streamOpcodes).map(streamIndex => ({
            streamIndex: parseInt(streamIndex, 10),
            opcodes: streamOpcodes[streamIndex]
        }));
    };

    const generateOpcode = () => {

        return allowedOperations.map((a, i) => {
            const operation = LIVE_PROCESSING[a];
            if (operation) {
                const assembledOperation = { id: operation.id };

                operation.data.forEach(({ id, limits: { default: defaultValue } }) => {
                    assembledOperation[id] = defaultValue;
                });

                return assembledOperation;
            }
        })
    }

    useEffect(() => {
        const count = profiles.length + 1;
        if (count === streamCount) return;
        setStreamCount(count);
        setTabIndex(0);

        setOptions(prevOptions =>
            count > prevOptions.length
                ? [...prevOptions, ...Array.from({ length: count - prevOptions.length }, () => generateOpcode())]
                : prevOptions.slice(0, count)
        );

        setChecked(prevChecked =>
            count > prevChecked.length
                ? [...prevChecked, ...Array.from({ length: count - prevChecked.length }, () => [])]
                : prevChecked.slice(0, count)
        );

    }, [profiles, streamCount]);

    useEffect(() => {

        let count = profiles.length + 1;
        const opSetup = generateOpcode();

        const assembledOperations = Array.from({ length: count }, () => [...opSetup]);
        const emptyChecked = Array.from({ length: count }, () => []);
        setChecked(emptyChecked);
        setOptions(assembledOperations);

    }, []);

    useEffect(() => {
        const checkedProcesses = createOpcodes();
        updateLiveProcessing(checkedProcesses);
    }, [options, checked, selectedStreams]);

    return (
        <SectionContainer noMargin>
            <Grid container spacing={2}>
                <Grid item xs={12} md={checked.flat().length > 0 ? 9 : 12}>
                    <TabContext value={tabIndex}>
                        <TabList onChange={handleTabbing}>
                            {
                                ["Passthrough", ...profiles]
                                    .map((p, i) => { return (<Tab key={i} label={i === 0 ? p : `${i}. Transcode`} value={i} />) })
                            }
                        </TabList>
                        {
                            isSecure &&
                            <Alert severity="error" icon={<FmdBad />} sx={{ mt: 2 }}>
                                Live processing assets are not protected with playback tokens at the moment.
                            </Alert>
                        }
                        {
                            ["Passthrough", ...profiles].map((p, streamIndex) => {
                                let label = streamIndex === 0 ? p : `${streamIndex}. Transcode`;
                                return (
                                    <TabContent key={streamIndex} value={tabIndex} index={streamIndex}>
                                        <SectionContainer
                                            contrast small title={`Live Processing for: ${label}`}
                                            underline={`Configure live processing setting for ${label}.`}
                                        >
                                            <Stack direction="row" useFlexGap spacing={1} flexWrap={"wrap"}>
                                                {
                                                    Object.keys(LIVE_PROCESSING)
                                                        .filter(key => allowedOperations.includes(key))
                                                        .map((o) => {
                                                            let opcode = LIVE_PROCESSING[o];
                                                            let isChecked = checked[streamIndex]?.includes(opcode?.id);
                                                            let isRecOrReplay = opcode.id === "replay" || opcode.id === "rec";

                                                            return (
                                                                <Box key={opcode?.id} sx={{
                                                                    width: 'auto', mt: 1, py: 1, px: 2, border: 1, width: { xs: '100%', md: checked.flat().length > 0 ? '100%' : '40%', lg: '40%' },
                                                                    borderColor: isChecked ? theme.palette.primary.main : theme.palette.grey[300], borderRadius: 2
                                                                }}>
                                                                    <FormControl sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    size="small"
                                                                                    checked={isChecked}
                                                                                    icon={<CircleOutlined />}
                                                                                    checkedIcon={<AddTask />}
                                                                                    onChange={handleCheckProcess(opcode.id, streamIndex)}
                                                                                />
                                                                            }
                                                                            label={
                                                                                <Fragment>
                                                                                    <Typography variant="body1" color={isChecked ? "primary" : "inherit"}>
                                                                                        {opcode?.title}
                                                                                    </Typography>
                                                                                    <Typography variant="body2" color={"textSecondary"}>
                                                                                        {opcode?.underline}
                                                                                    </Typography>
                                                                                </Fragment>
                                                                            }
                                                                        />
                                                                        <Collapse in={isChecked}>
                                                                            {
                                                                                opcode &&
                                                                                opcode.data.map((d, i) => {
                                                                                    let option = options[streamIndex]?.find(opt => opt.id === opcode?.id);
                                                                                    let min = d.limits.min;
                                                                                    let max = d.limits?.max;
                                                                                    let def = d.limits.default;

                                                                                    // if (streamIndex === 0 && opcode.id === "rec" && option) console.log(option, Boolean(option[d.id] === null))

                                                                                    return (
                                                                                        <Box key={`${opcode?.title}-${i}-${streamIndex}`} sx={{ my: 1 }}>
                                                                                            {/* {
                                                                                                isRecOrReplay &&
                                                                                                <FormControlLabel
                                                                                                    onChange={handleSelectEntireStream(opcode.id, d.id, streamIndex, min)}
                                                                                                    control={
                                                                                                        <Checkbox
                                                                                                            defaultChecked={option && Boolean(option[d.id] === null)}
                                                                                                            checked={option && Boolean(option[d.id] === null)}
                                                                                                            icon={<CircleOutlined />} checkedIcon={<CheckCircle />} size="small"
                                                                                                        />
                                                                                                    }
                                                                                                    label={<Typography variant="body2">Entire Stream</Typography>}
                                                                                                />
                                                                                            } */}
                                                                                            <TextField
                                                                                                // sx={{ textTransform: 'capitalize' }}
                                                                                                variant="standard" disabled={!isChecked} label={`${d.id} (s)`} fullWidth
                                                                                                value={option && option[d.id] !== null ? option[d.id] : ""}
                                                                                                helperText={`Min: ${min}${max ? ` | Max: ${max}` : ""}${!def ? ` | Default: Entire Stream` : ""}`}
                                                                                                onChange={handleUpdate(opcode.id, d.id, streamIndex)}
                                                                                                onBlur={handleBlur(opcode.id, d.id, min, max, def, streamIndex)}
                                                                                            />
                                                                                        </Box>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Collapse>
                                                                    </FormControl>
                                                                </Box>
                                                            )
                                                        })
                                                }
                                            </Stack>
                                        </SectionContainer>
                                    </TabContent>
                                )
                            })
                        }
                    </TabContext>
                </Grid>
                {
                    checked.flat().length > 0 &&
                    <Grid item xs={12} md={3}>
                        <SectionContainer
                            small title={`Enabled Live Processes`} info
                            extraStyle={{ border: `1px solid ${theme.palette.info.main}`, backgroundColor: alpha(theme.palette.info.main, 0.05) }}
                        >
                            {
                                checked.map((c, streamIndex) => {
                                    let label = streamIndex === 0 ? "Passthrough" : `${streamIndex}. Transcode`;
                                    return c.length > 0 && (
                                        <Box key={`${streamIndex}`} sx={{ mt: 1 }}>
                                            <SectionContainer small info title={label}>
                                                <Stack direction="row" useFlexGap flexWrap={"wrap"} spacing={1} sx={{ mt: 1 }}>
                                                    {
                                                        c.map((opcodeLabel) => {
                                                            let opcode = LIVE_PROCESSING[opcodeLabel];
                                                            return (
                                                                <Chip
                                                                    key={`${streamIndex}-${opcodeLabel}`}
                                                                    label={opcode.title}
                                                                    icon={<TaskAltOutlined />}
                                                                    onDelete={handleCheckProcess(opcode.id, streamIndex)}
                                                                    color="info" variant="outlined" size="small"
                                                                />
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                            </SectionContainer>

                                        </Box>
                                    )
                                })
                            }
                        </SectionContainer>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Alert severity="info" icon={<Error />}>
                        <i>*Additional charges may apply based on actual usage and package limits.</i>
                    </Alert>
                </Grid>
            </Grid>
        </SectionContainer >

    )
}
