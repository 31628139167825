import React, { Fragment, useEffect, useState } from 'react';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Grid, Step, StepLabel, Stepper, StepContent, Typography, FormControl, InputLabel, Select, Collapse, MenuItem, Button, FormControlLabel, Checkbox, FormGroup } from '@mui/material';
import { Add, DeleteOutline } from '@mui/icons-material';

import SectionContainer from '../global/SectionContainer';
import SnackbarMessage from '../global/SnackbarMessage';
import AddTags from '../create/AddTags';
import SectionHeader from '../global/SectionHeader';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {

    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: `700 !important`
    },
}));

const Profile = (props) => {
    const classes = useStyles();
    const [profiles, setProfiles] = useState([]);
    const [profile, setProfile] = useState(props.profile);
    const [tags, setTags] = useState(props.tags);

    const setUpdatedTagList = (newTagList) => { setTags(newTagList); }
    const handleCheckProfile = (event) => { props.handleCheckProfile(event.target.checked, props.index); }

    const handleSelectedProfile = (event) => {
        let newProfile = event.target.value
        setProfile(newProfile);
    }

    useEffect(() => { props.addTags(props.index, tags); }, [tags])
    useEffect(() => { setProfiles(props.profiles); }, [props.profiles])
    useEffect(() => { props.addProfile(props.index, profile) }, [profile])

    return (
        <FormGroup className={classes.formGroup}>
            <Grid container>
                <Grid item xs={12}>
                    <FormControlLabel
                        classes={{ label: classes.checkboxLabel }}
                        control={
                            <Checkbox
                                color="primary"
                                checked={props.isActive >= props.index}
                                onChange={handleCheckProfile}
                            />
                        }
                        label={`Add ${props.index + 1}. Transcoding Profile`}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl sx={{ width: '100%', mb: 0.5 }}>
                        <Select
                            variant="standard"
                            color="primary"
                            disabled={!(props.isActive >= props.index)}
                            value={profile}
                            onChange={handleSelectedProfile}
                        >
                            {
                                profiles.map((p, i) => (
                                    <MenuItem key={p.name} value={p.name}>
                                        {
                                            p.vtrans
                                                ?
                                                `Resolution:
                                ${p.vtrans.width === 0
                                                    ? ` as input,`
                                                    : ` ${p.vtrans.width} x ${p.vtrans.height},`
                                                }
                                Bitrate: ${p.vtrans.bitrate} kbit/s,
                                FPS: ${p.vtrans.fps === 0 ? "as input" : p.vtrans.fps}
                                `
                                                : "Empty"
                                        }
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </FormGroup>
    )
}

export default function AddTranscoding(props) {
    const classes = useStyles();
    const { allowed } = props;
    const maximalTranscodes = 3;
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [activeStep, setActiveStep] = useState(1);
    const [transcodingProfiles, setTranscodingProfiles] = useState([]);
    const [selectedTags, setSelectedTags] = useState([], [], [])
    const [selectedProfiles, setSelectedProfiles] = useState([
        "vtrans2-852x480x800x25",
        "vtrans2-640x360x400x25",
        "vtrans2-426x240x200x15"
    ]);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const fetchProfiles = () => {
        if (!allowed) return;
        bintu.getTranscodingProfiles()
            .then((response) => {
                if (response.success) {
                    setTranscodingProfiles(response.data)
                }
            })
            .catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleStep = (isChecked, index) => {
        let active = isChecked ? index : index - 1;
        setActiveStep(active);
    }

    const addNewTags = (index, tags) => {
        let selectedTagList = selectedTags;
        selectedTagList[index] = tags;
        setSelectedTags(selectedTagList);
        updateProfiles();
    }

    const addNewProfile = (index, profile) => {
        let selectedProfileList = selectedProfiles;
        selectedProfileList[index] = profile;
        setSelectedProfiles(selectedProfileList);
        updateProfiles();
    }

    const updateProfiles = () => {
        let appliedProfiles = selectedProfiles.filter((p, i) => i <= (activeStep)).map((p, i) => { return ({ profile: p, tags: selectedTags[i] }) })
        props.applyTranscodingProfiles(appliedProfiles)
    }

    useEffect(() => { updateProfiles(); }, [activeStep])
    useEffect(() => { if (allowed && transcodingProfiles.length === 0) fetchProfiles(); }, [])
    useEffect(() => { updateProfiles(); }, [])

    return (
        <SectionContainer>
            {
                !props.hideHeader &&
                <SectionHeader
                    title="Set Transcoding Profiles"
                    underline="Add up to three transcoding profiles to enable Adaptive Bitrate (ABR) for your stream."
                />
            }
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Collapse in={transcodingProfiles.length > 0}>
                <Grid container className={classes.root}>
                    {

                        [...Array(maximalTranscodes)].map((p, i) => (
                            <Grid item key={i} xs={12} sm={6} lg={4}>
                                <Profile
                                    handleCheckProfile={handleStep}
                                    index={i}
                                    profiles={transcodingProfiles}
                                    addProfile={addNewProfile}
                                    addTags={addNewTags}
                                    profile={selectedProfiles[i]}
                                    isActive={activeStep}
                                />
                            </Grid>
                        ))
                    }
                </Grid >
            </Collapse>
        </SectionContainer>

    )
}
