export const GET_STATE = (state) => {
    const states = [
        "Uninitialized",
        "Idle",
        "Ready",
        "Loading",
        "Playing",
        "Paused",
        "Buffering",
        "Unknown",
        "Playback not started",
        "Playback suspended",
        "Pausing",
        "Playback Error",
        "Reconnection imminent",
        "Connection Error",
        "Destroying",
        "Playback restarting",
        "Visibility hidden",
        "Not enough data",
        "Source Stream Stopped"
    ];
    return states[state - 1];
}

export const setup = ({ player, config }) => {
    return new Promise((resolve, reject) => {
        player.setup(config).then(function (config) {
            resolve({ success: true, data: config })
            console.log('setup ok with config: ' + JSON.stringify(config));
        }, function (error) {
            console.log(error);
        });
    })
}

export const updateSource = ({ player, source }) => {
    return new Promise((resolve, reject) => {
        player.updateSource(source).then(function (config) {
            resolve({ success: true, data: config })
            console.log('update source ok with config: ' + JSON.stringify(config));
        }, function (error) {
            console.log(error);
        });
    })
}

export const switchStream = ({ player, index }) => {
    return new Promise((resolve, reject) => {
        player.switchStream(index).then(function (config) {
            resolve({ success: true, data: config })
            console.log('switch stream initialized with config: ' + JSON.stringify(config));
        }, function (error) {
            console.log(error);
        });
    })
}

export const setAdaption = ({ player, rule }) => {
    return new Promise((resolve, reject) => {
        player.setAdaption({ rule });
        console.log('set adaption with rule ' + rule)
    })
}

export const destroy = (player) => {
    player.destroy();
    console.log('destroy player')
}
