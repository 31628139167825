import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ArrowRightAlt } from '@mui/icons-material';
import clsx from 'clsx';

export default function SectionHeader(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const {
        title, info, specialHeader, small, button, extend, interact,
        icon, clicked, underline, center, orange, light, caution
    } = props;


    const textColor = clsx({
        [theme.palette.common.white]: orange,
        [theme.palette.primary.light]: light,
        [theme.palette.error.main]: caution,
        [theme.palette.info.main]: info,
        [theme.palette.primary.main]: !orange && !light && !caution && !info
    });

    return (
        <Fragment>
            <Stack direction="row" spacing={2} alignItems={"center"} sx={{ mb: underline && .5 }}>
                <Box sx={{ color: textColor, lineHeight: 1 }}>
                    {
                        specialHeader
                            ?
                            <Typography variant="h3" sx={{ fontFamily: 'Cocogoose', fontWeight: 600, lineHeight: 1.1 }}>
                                {title}
                            </Typography>
                            :
                            <Typography variant={small ? "body1" : "h4"} sx={{ lineHeight: 1.1, fontWeight: 700 }}>
                                {title}
                            </Typography>
                    }

                </Box>
                <Grid item>
                    {
                        button && extend &&
                        <Button
                            size="small" variant="outlined" color="primary"
                            endIcon={<ArrowRightAlt />} onClick={clicked}
                            sx={{ lineHeight: 1.2, py: 1, px: 1.5 }}
                        >
                            {button}
                        </Button>
                    }
                    {
                        button && interact &&
                        <Button
                            size="small" variant="contained" color="primary"
                            endIcon={icon} onClick={clicked}
                            sx={{ lineHeight: 1.2, py: 1, px: 1.5 }}
                        >
                            {button}
                        </Button>
                    }
                </Grid>
            </Stack>
            {
                underline &&
                <Typography
                    variant={small ? "body2" : "body1"}
                    color="textSecondary" sx={{ lineHeight: 1.3, textAlign: center && 'center' }}
                >
                    {underline}
                </Typography>

            }
        </Fragment >
    )
}
