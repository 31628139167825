import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';

import './MuiClassNameSetup';

import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Badge, Collapse, Drawer, Hidden, Box, Toolbar, Typography, IconButton, Tooltip, Grid, alpha, Icon, Button, Stack } from '@mui/material';
import { AccountCircleOutlined, ArrowRight, ArrowRightAlt, ChevronLeftOutlined, ChevronRightOutlined, NotesOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import * as m from '../../utils/helper/methods';
import * as ls from '../../utils/helper/ls-vars';
import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';
import * as metrics from '../../analytics/utils/api-requests';

import orangeLogo from '../../assets/orange-nsc.png';
import whiteLogo from '../../assets/nsc.png'
import whiteCheetha from '../../assets/white_cheetha.png'
import orangeCheetha from '../../assets/orange-cheetha.png'

import MainMenu from './MainMenu';
import ProfileMenu from './ProfileMenu';
import Search from '../streams/Search';
import { grey } from '@mui/material/colors';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        '& .NanoPaper-root': {
            borderRight: 'none'
            // borderRight: `1px solid ${theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.text.secondary}`
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    logo: {
        height: 30,
        marginRight: theme.spacing(2)
    },
    avatarIcon: {
        color: `${theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.primary.main} !important`
    },
    cheetha: {
        height: 35,
        margin: '0 auto'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        minHeight: '100%',
        [theme.breakpoints.up('xs')]: {
            width: '100%',
            maxWidth: `100%`,
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            maxWidth: '100%',
        }
    },
}));

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('md')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    background: theme.palette.background.default,
    ...theme.mixins.toolbar,
}));

const DynamicAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: 999,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DynamicDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '& .NanoPaper-root': {
            zIndex: 0,
        },
        ...(open && {
            ...openedMixin(theme),
            '& .NanoDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .NanoDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function Layout(props) {
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState(null);
    const [hasAccess, setHasAccess] = useState(m.CHECK_ACCESS());
    const [open, setOpen] = useState(false);
    const [isTrial, setIsTrial] = useState(false);

    const [profileAnchor, setProfileAnchor] = useState(null);
    const openProfileMenu = Boolean(profileAnchor);

    const now = moment().format('MM/DD/YYYY hh:mm a (UTC)');

    const handleClickedProfile = (event) => { setProfileAnchor(event.currentTarget); }
    const handleCloseProfile = () => { setProfileAnchor(null); }
    const handleDrawerOpen = () => { setOpen(true); };
    const handleDrawerClose = () => { setOpen(false); };
    const handleRedirect = (link) => () => { navigate(link); }

    const checkAccess = () => {
        setHasAccess(m.CHECK_ACCESS());
        checkToken();
    }

    // rewrite to be checked // put in playout page
    const checkToken = () => {
        if (hasAccess) {
            let orga = JSON.parse(sessionStorage.getItem(ls.BINTU_ORGA));
            let apikey = sessionStorage.getItem(ls.BINTU_TOKEN);
            let decodedToken = m.DECODE_TOKEN(sessionStorage.getItem(ls.H5LIVE_HASH_SECURE));
            if (orga && orga.secure && decodedToken) {
                // check mit moment if isafter
                const isInvalid = moment.unix(decodedToken.exp).utc().format('MM/DD/YYYY hh:mm a (UTC)') < now;
                if (isInvalid) {
                    let data = {
                        tag: "nanostream-cloud-dashboard-player-token",
                        exp: Math.round(moment(new Date(Date.now())).add(24, 'hours') / 1000),
                        orgawide: true
                    };
                    bintu.createH5LiveSecureToken({ apikey, data })
                        .then((response) => {
                            if (response.success) {
                                sessionStorage.setItem(ls.H5LIVE_HASH_SECURE, JSON.stringify(response.data.data.token));
                            }
                        }).catch((error) => {
                            if (!error.success) {
                                console.log("error", error)
                            }
                        })
                    window.dispatchEvent(new Event("storage")); // Register storage changes
                }
            }
        }
    }

    useEffect(() => {
        if (redirect) {
            setTimeout(() => {
                setRedirect(null)
            }, 100)
        }
    }, [redirect])

    useEffect(() => {
        checkToken();
        window.addEventListener('storage', checkAccess)
    }, [])

    useEffect(() => {
        if (hasAccess) {
            let orga = JSON.parse(sessionStorage.getItem(ls.BINTU_ORGA))
            if (orga && orga.trial) {
                setIsTrial(orga.trial);
            }
        }
    }, [hasAccess])

    return (
        <div className={clsx({ [classes.root]: hasAccess })}>
            {redirect}
            <Collapse in={hasAccess}>
                <DynamicAppBar elevation={0} position="fixed" open={open}>
                    <Toolbar sx={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        justifyContent: { xs: 'space-between' },
                        // backgroundImage: theme.palette.mode === 'light'
                        //     ? `repeating-radial-gradient( circle at 0 0, transparent 0, ${alpha(theme.palette.primary.light, 0.1)} 50px ), repeating-linear-gradient( ${theme.palette.primary.main}, ${theme.palette.primary.main} )`
                        //     : `repeating-radial-gradient( circle at 0 0, transparent 0, ${alpha(grey[900], 0.2)} 30px ), repeating-linear-gradient( ${theme.palette.background.default}, ${theme.palette.background.default} )`
                    }}>
                        <IconButton
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                mr: { md: theme.spacing(5) },
                                ...(open && { display: { md: 'none' } }),
                                // color: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.primary.main
                            }}
                        >
                            <NotesOutlined sx={{ color: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.primary.main }} />
                        </IconButton>

                        <Badge
                            sx={{ cursor: 'pointer' }}
                            onClick={handleRedirect(link.DASHBOARD)}
                            color={theme.palette.mode === 'light' ? "secondary" : "primary"}
                            badgeContent="TRIAL" invisible={!isTrial}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <img alt="nanocosmos" className={classes.logo} src={theme.palette.mode === 'light' ? whiteLogo : orangeLogo} />
                            <Typography variant="h4" sx={{ fontFamily: 'Cocogoose', fontWeight: 600, color: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.primary.main }}>
                                Dashboard
                            </Typography>
                        </Badge>
                        <Hidden mdUp>
                            <Tooltip title="Search stream">
                                <Search icon />
                            </Tooltip>
                        </Hidden>
                        <Hidden mdDown>
                            <Grid container alignItems="center" justifyContent={"flex-end"}>
                                <Grid item>
                                    <Search button />
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Account Settings">
                                        <IconButton
                                            disableRipple
                                            onClick={handleClickedProfile}
                                            sx={{ ml: "auto" }}
                                            aria-controls={openProfileMenu ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openProfileMenu ? 'true' : undefined}
                                        >
                                            <AccountCircleOutlined className={classes.avatarIcon} />
                                        </IconButton>
                                    </Tooltip>
                                    <ProfileMenu
                                        offerUpgrade={isTrial}
                                        anchorEl={profileAnchor}
                                        open={openProfileMenu}
                                        clicked={handleCloseProfile}
                                    />
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Toolbar>
                </DynamicAppBar>
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        open={open}
                        onClose={handleDrawerClose}
                        ModalProps={{ keepMountewd: true }}
                        sx={{
                            '& .NanoDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                                whiteSpace: 'nowrap',
                            },
                        }}>
                        <MainMenu
                            open={open}
                            close={handleDrawerClose}
                        />
                    </Drawer>
                </Hidden>
                <Hidden mdDown>
                    <DynamicDrawer variant="permanent" open={open}>
                        <DrawerHeader>
                            {/* <img alt="nanocosmos-cheetha" className={classes.cheetha} src={theme.palette.mode === 'light' ? orangeCheetha : whiteCheetha} /> */}
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'rtl' ? <ChevronRightOutlined /> : <ChevronLeftOutlined />}
                            </IconButton>
                        </DrawerHeader>
                        <MainMenu
                            open={open}
                            close={handleDrawerClose}
                        />
                    </DynamicDrawer>
                </Hidden>
            </Collapse>
            <Box
                component="main"
                className={clsx({ [classes.content]: hasAccess })}
                sx={{
                    ...(hasAccess && {
                        flexGrow: 1, p: 3,
                    }),

                    ...(!open && {
                        maxWidth: {
                            md: hasAccess ? `calc(100% - 65px)` : '100%'
                        }
                    }),
                    ...(open && {
                        width: {
                            md: `calc(100% - ${drawerWidth}px)`
                        }
                    }),
                }}
            >
                <Collapse in={hasAccess}>
                    <DrawerHeader />
                </Collapse>
                {props.children}
            </Box>
        </div >
    );
}

{/* <Box sx={{ bgcolor: '#fff', p: .5 }}>
<Stack direction="row" alignItems={"center"} justifyContent={"center"} useFlexGap spacing={1} flexWrap={"wrap"}>
    <Typography variant="body1" color="primary">
        <b>nanoStream User Roles are here!</b> Learn more about the feature.
    </Typography>
    <Button
        disableFocusRipple disableRipple disableTouchRipple disableElevation
        variant="text" sx={{ textDecoration: 'underline' }} href="https://www.nanocosmos.de/blog/nanostream-user-roles-are-here/"
        endIcon={<ArrowRightAlt />}>
        Let's go
    </Button>
</Stack>
</Box> */}