import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, Box, Chip, Divider, Grid, ListItem, ListItemIcon, ListItemText, Skeleton, Stack, Typography, alpha } from '@mui/material';
import { useTheme } from '@mui/styles';
import moment from 'moment';
import { getByteUsageHourly } from '../../utils/api-requests';
import { timeDateConverter } from '../../utils/helper/time-converter';
import { Backup, BackupOutlined, CalendarMonth, CallMade, CloudDownload, CloudDownloadOutlined, SouthEast, Subscriptions, WbCloudy, WbCloudyOutlined } from '@mui/icons-material';
import { convertBytes } from '../../utils/helper/byte-formatter';
import { grey } from '@mui/material/colors';

export default function MonthToDate(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(true);
    const [noAccess, setNoAccess] = useState(false);

    const startOfMonth = moment().startOf('month').format('DD MMMM YY');
    const now = moment().format('DD MMMM YYYY');
    const startOfPrevMonth = moment().subtract(1, 'month').startOf('month').format('DD MMMM YY');
    const prevMonthToday = moment().subtract(1, 'month').format('DD MMMM YYYY');

    const prevMonth = moment().subtract(1, 'month').format('MMMM');

    let interval = "month";

    const [totalData, setData] = useState([
        { id: "total", label: 'Usage', prev: 0, now: 0, icon: <WbCloudyOutlined /> },
        { id: "playout", label: 'Playout', prev: 0, now: 0, icon: <CloudDownloadOutlined /> },
        { id: "ingest", label: 'Ingest', prev: 0, now: 0, icon: <BackupOutlined /> }
    ]);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setShowError(false);
    }

    const calculateByteDifference = (prevBytes, noBytes) => {
        if (prevBytes === 0) return noBytes === 0 ? '0.0' : '100.0';
        return (((noBytes - prevBytes) / prevBytes) * 100).toFixed(0);
    };

    const getTotalUsage = (filter, type) => {
        getByteUsageHourly(filter)
            .then((response) => {
                if (response.success) {
                    let result = response.data[0];
                    let newData = {
                        ingest: result.ingest.bytes,
                        playout: result.playout.bytes,
                        total: result.ingest.bytes + result.playout.bytes
                    }

                    setData(prevData => prevData.map(item => {
                        if (Object.keys(newData).includes(item.id)) {
                            return { ...item, ...prevData, [type]: newData[item.id] };
                        }
                        return { ...totalData, ...item };
                    }));
                    setLoading(false);
                }
            })
            .catch((error) => {
                if (!error.success) {
                    setLoading(false);
                    if (error?.errorCode === 105002) {
                        setNoAccess(true);
                        return;
                    }
                    setError(error);
                    setShowError(true);
                }
            });
    }

    useEffect(() => {
        const now = moment().utc();
        const lastMonthFilter = {
            from: timeDateConverter.lastMonthToDate.start(now).format('YYYY-MM-DDTHH:mm'),
            to: timeDateConverter.lastMonthToDate.end(now).format('YYYY-MM-DDTHH:mm'),
            interval
        };
        const currentMonthFilter = {
            from: timeDateConverter.currentMonth.start(now).format('YYYY-MM-DDTHH:mm'),
            to: timeDateConverter.currentMonth.end(now).format('YYYY-MM-DDTHH:mm'),
            interval
        };
        getTotalUsage(lastMonthFilter, "prev");
        getTotalUsage(currentMonthFilter, "now");
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>  {
                !noAccess &&
                // ?
                // <Box>
                //     Hello
                // </Box>
                // :
                <Stack direction="row" spacing={2} flexWrap={"wrap"} useFlexGap>
                    <Fragment>
                        {
                            totalData.map((data, i) => {
                                let chip = calculateByteDifference(data.prev, data.now);
                                return (
                                    <Box key={i} sx={{ width: { xs: '100%', sm: 'auto' } }}>
                                        <Box sx={{
                                            p: 2,
                                            bgcolor: theme.palette.primary.main,
                                            borderTopRightRadius: 10, borderTopLeftRadius: 10,
                                            backgroundImage: `repeating-radial-gradient( circle at 0 0, transparent 0, ${alpha(theme.palette.primary.main, 0.3)} 15px ),
                                         repeating-linear-gradient( ${theme.palette.primary.main}, ${theme.palette.primary.light} )`,
                                        }}>
                                            <Box sx={{ display: 'inline-block', borderRadius: '50%', p: .8, bgcolor: alpha(theme.palette.common.white, 0.3) }}>
                                                <Avatar sx={{ boxShadow: theme.shadows[5], bgcolor: theme.palette.common.white, color: theme.palette.primary.main }}>
                                                    {data.icon}
                                                </Avatar>
                                            </Box>
                                            <Stack direction="row" spacing={1} alignItems={"center"} sx={{ color: theme.palette.common.white }}>
                                                <Typography variant="h2" sx={{ fontFamily: 'Cocogoose', fontWeight: 400 }}>
                                                    <b>{convertBytes(data.now).value}</b>
                                                </Typography>
                                                <Typography variant="button" sx={{ lineHeight: 1 }}>
                                                    {convertBytes(data.now).unit} <br />
                                                    Total {data.label}
                                                </Typography>
                                            </Stack>
                                            {
                                                parseFloat(chip) !== 0 &&
                                                <Chip
                                                    size="small"
                                                    label={`${chip > 0 ? '+' : ''}${chip}%`}
                                                    color={chip > 0 ? "success" : "error"}
                                                    icon={
                                                        chip > 0
                                                            ? <CallMade fontSize="inherit" />
                                                            : <SouthEast fontSize="inherit" />
                                                    }
                                                />
                                            }
                                            <Typography variant="overline" color="white" sx={{ mt: 1, fontStyle: 'italic', display: 'block' }}>
                                                01- {now}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ p: 2, color: theme.palette.common.white, bgcolor: theme.palette.primary.light, borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }}>
                                            <Typography variant="button" sx={{ lineHeight: 1 }}>
                                                Total {data.label}: {convertBytes(data.prev).value + ' ' + convertBytes(data.prev).unit}
                                            </Typography>
                                            <Typography variant="overline" sx={{ display: 'block', fontStyle: 'italic' }}>
                                                01- {prevMonthToday}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Fragment>
                </Stack>
            }
            </Grid>
        </Grid >
    )
}
