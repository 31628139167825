import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';

import clsx from 'clsx';

import * as link from '../../utils/helper/link-config';
import * as ls from '../../utils/helper/ls-vars';
import * as m from '../../utils/helper/methods';

import { makeStyles } from '@mui/styles';
import { alpha, Collapse, Hidden, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { AccountCircleOutlined, CameraOutlined, AddCircleOutline, DashboardOutlined, ExitToAppOutlined, InfoOutlined, LockOutlined, PieChartOutlined, PlaylistPlayOutlined, QuestionAnswerOutlined, ContactSupportOutlined, HelpCenterOutlined, ChatRounded, BarChartOutlined, ExpandMoreOutlined, ExpandLessOutlined, QueryStats, MapOutlined, SlowMotionVideoOutlined, SsidChartOutlined, QueryStatsOutlined, ManageHistoryOutlined, ShutterSpeedOutlined, AssessmentOutlined, SettingsOutlined, AnnouncementOutlined, RunningWithErrorsOutlined, UpcomingOutlined } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    menuRoot: {
        backgroundColor: theme.palette.background.default,
        borderRight: "none !important",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    list: {
        padding: 0,
    },
    label: {
        lineHeight: 1,
    },
    menuItem: {
        color: `${theme.palette.primary.main} !important`,
        textDecoration: 'none',
        height: '100%',
        // width: '100%',
        padding: theme.spacing(1, 1),
        margin: `${theme.spacing(1)}px auto`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.spacing(0.5),
        '&:hover': {
            cursor: 'pointer',
            background: alpha(theme.palette.primary.main, 0.2),
            color: theme.palette.primary.main
        }
    },
    analyticsMenuItem: {
        color: `${theme.palette.secondary.main} !important`,
    },
    openDrawer: {
        width: '100%',
        justifyContent: 'flex-start',
    },
    active: {
        color: `${theme.palette.common.white} !important`,
        backgroundImage: `linear-gradient(to left, ${alpha(theme.palette.primary.main, 0.8)}, ${theme.palette.primary.main}) !important`
    },
}))

export default function MenuItems(props) {
    const classes = useStyles();
    const orga = JSON.parse(sessionStorage.getItem(ls.BINTU_ORGA));
    const [openAnalytics, setOpenAnalytics] = useState(true);
    const [menu] = useState([
        { title: "Home", link: link.DASHBOARD, icon: <DashboardOutlined /> },
        { title: "Streams", link: link.STREAMS, icon: <PlaylistPlayOutlined /> },
        { title: "Create Stream", link: link.CREATE_STREAM, icon: <AddCircleOutline /> },
        { title: "Webcaster", link: link.WEBCASTER, icon: <CameraOutlined /> },
        { title: "Metrics", link: link.METRICS, icon: <PieChartOutlined /> },
        { title: "Alerts", link: link.ALERTS, icon: <UpcomingOutlined /> },
        // { title: "Worldmap", link: link.WORLDMAP, icon: <MapOutlined /> },
    ]);
    const [secureMenuItem] = useState({ title: "Secure Playback Token", link: link.PLAYBACK_TOKEN, icon: <LockOutlined /> });
    const [bottomMenu] = useState([
        { title: "My Organisation", link: link.USER, icon: <AccountCircleOutlined /> },
        { title: "About", link: link.ABOUT, icon: <SettingsOutlined /> },
        { title: "Help Center", link: link.SUPPORT, icon: <HelpCenterOutlined /> },
        { title: "Logout", link: link.LOGOUT, icon: <ExitToAppOutlined /> },
    ]);

    const handleExpandAnalytics = () => {
        setOpenAnalytics(!openAnalytics);
    }

    return (
        <div className={classes.menuRoot}>
            <List>
                {
                    menu.map((item) => (
                        <ListItem className={classes.list} key={item.title}>
                            <NavLink
                                end
                                to={item.link}
                                onClick={props.close}
                                className={({ isActive }) => (
                                    clsx(classes.menuItem, {
                                        [classes.openDrawer]: props.open,
                                        [classes.active]: isActive
                                    })
                                )}
                            >
                                {item.icon}
                                {
                                    props.open
                                    &&
                                    <Typography ml={1} className={classes.label} variant="body1">
                                        {item.title}
                                    </Typography>
                                }
                            </NavLink>
                        </ListItem>
                    ))
                }
                {
                    orga?.secure
                    &&
                    <ListItem className={classes.list}>
                        <NavLink
                            end
                            to={secureMenuItem.link}
                            onClick={props.close}
                            className={({ isActive }) => (
                                clsx(classes.menuItem, {
                                    [classes.openDrawer]: props.open,
                                    [classes.active]: isActive
                                })
                            )}
                        >
                            {secureMenuItem.icon}
                            {
                                props.open
                                &&
                                <Typography ml={1} className={classes.label} variant="body1">
                                    {secureMenuItem.title}
                                </Typography>
                            }
                        </NavLink>
                    </ListItem>
                }
            </List>
            <Hidden mdUp>
                <List>
                    {
                        bottomMenu.map((item) => (
                            <ListItem className={classes.list} key={item.title}>
                                <NavLink
                                    end
                                    to={item.link}
                                    onClick={props.close}
                                    className={({ isActive }) => (
                                        clsx(classes.menuItem, {
                                            [classes.openDrawer]: props.open,
                                            [classes.active]: isActive
                                        })
                                    )}
                                >
                                    {item.icon}
                                    {
                                        props.open
                                        &&
                                        <Typography ml={1} className={classes.label} variant="body1">
                                            {item.title}
                                        </Typography>
                                    }
                                </NavLink>
                            </ListItem>
                        ))
                    }
                </List>
            </Hidden>
        </div>
    )
}