import { CameraOutlined, ComputerOutlined, GridView, PieChartOutlined, PlayCircleOutlineOutlined, PublicOutlined } from '@mui/icons-material';

import * as link from './link-config';

export const SUPPORT = {
    DASHBOARD: {
        title: "nanoStream Cloud Dashboard",
        underline: "The nanoStream Cloud Dashboard is a web-based tool providing users with a comprehensive overview of their streaming activities.",
        icon: <ComputerOutlined />,
        link: link.DASHBOARD_OVERVIEW,
        links: [
            {
                title: "Stream Overview",
                link: "https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Stream_Overview",
            },
            {
                title: "Adaptive Bitrate (ABR) and Live Transcoding",
                link: "https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_ABR_Transcoding",
            },
            {
                title: "Thumbnails",
                link: "https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Thumbnail",
            },
            {
                title: "Secure Playback & Secure Token",
                link: "https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Secure",
            },
            {
                title: "Alerts and Advices",
                link: "https://docs.nanocosmos.de/docs/cloud-frontend-v3/Dashboard_Alerting",
            },
        ]
    },
    CLOUD: {
        title: "nanoStream Cloud API",
        underline: "nanoStream Cloud is a unique live streaming platform offering ultra low latency live streaming.",
        icon: <PublicOutlined />,
        link: link.NSC_INTRO,
        links: [
            {
                title: "nanoStream Cloud API",
                link: link.NSC_API,
            },
            {
                title: "Live Recording and VOD",
                link: "https://docs.nanocosmos.de/docs/cloud/live-recording-vod",
            },
            {
                title: "nanoStream Guardian",
                link: "https://docs.nanocosmos.de/docs/cloud/guardian",
            },
            {
                title: "Custom web hooks",
                link: "https://docs.nanocosmos.de/docs/cloud/bintu_custom_webhooks",
            },
            {
                title: "Latest News",
                link: link.NSC_NEWS,
            },
        ]
    },
    H5LIVE: {
        title: "nanoStream H5Live Player",
        underline: "nanoStream H5Live is a client-server technology offering ultra-low latency live streaming in all HTML5 web browsers.",
        icon: <PlayCircleOutlineOutlined />,
        link: link.H5LIVE_DOCS,
        links: [
            {
                title: "nanoStream H5Live Player API",
                link: link.H5LIVE_API,
            },
            {
                title: "Autoplay",
                link: "https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_autoplay",
            },
            {
                title: "Fast playback start",
                link: "https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_feature_fast_start",
            },
            {
                title: "Latency Control Mode",
                link: "https://docs.nanocosmos.de/docs/nanoplayer/nanoplayer_feature_latency_control_modes",
            },
            {
                title: "Latest Release",
                link: link.H5LIVE_LATEST_RELEASE,
            },
        ]
    },
    METRICS: {
        title: "nanoStream Analytics",
        underline: "nanoStream Cloud Analytics helps monitor and analyze the performance and quality of your live streams.",
        icon: <PieChartOutlined />,
        link: link.METRICS_DOCS,
        links: [
            {
                title: "nanoStream Analytics API",
                link: link.METRICS_API_DOC,
            },
            {
                title: "Error Code Definitions",
                link: "https://docs.nanocosmos.de/docs/analytics/api-error-codes"
            },
            {
                title: "Worldmap",
                link: "https://docs.nanocosmos.de/docs/analytics/worldmap"
            },
            {
                title: "Alerts and Advices",
                link: "https://docs.nanocosmos.de/docs/analytics/alerting"
            },
            {
                title: "Latest News",
                link: link.METRICS_NEWS,
            }
        ]
    },
    WEBCASTER: {
        title: "nanoStream Webcaster",
        underline: "nanoStream Webcaster is a powerful live encoder for broadcasting directly into nanoStream Cloud.",
        icon: <CameraOutlined />,
        link: link.WEBCASTER_INTRO,
        links: [
            {
                title: "nanoStream Webcaster API",
                link: link.WEBCASTER_API,
            },
            {
                title: "Input Sources",
                link: "https://docs.nanocosmos.de/docs/webrtc/nanostream_webrtc_input_sources",
            },
            {
                title: "Multiple Webcasts",
                link: "https://docs.nanocosmos.de/docs/webrtc/nanostream_webrtc_multiple_webcasts",
            },
            {
                title: "Stats and Metrics",
                link: "https://docs.nanocosmos.de/docs/webrtc/nanostream_webrtc_stats_and_metrics",
            },
            {
                title: "Latest News",
                link: link.WEBCASTER_NEWS,
            }
        ]
    }
}
