import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/styles';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, Stack, Tab, Tabs, Typography, alpha } from '@mui/material';
import { ExpandMore, HelpOutline, OpenInNew, PieChartOutline, PlayCircleOutline } from '@mui/icons-material';

import streams from '../assets/streams-background.jpeg';
import datagrid from '../assets/datagrid_background.jpeg';
import world from '../assets/world-background.jpeg';
import dashboard from '../assets/city-background.jpeg'

import { SUPPORT } from '../utils/helper/support';
import { BINTU_ORGA } from '../utils/helper/ls-vars';
import { GETTING_STARTED_CREATE_STREAM, METRICS_DOCS, NANO_SUPPORT, SOCIAL_MEDIA } from '../utils/helper/link-config';
import { usePermission } from '../utils/helper/hooks';
import permissionConfig from '../utils/permissions/permission_config';

import SectionHeader from '../components/global/SectionHeader';
import ArticleCard from '../components/global/ArticleCard';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            hidden={value !== index}
        >
            {value === index && (
                <Fragment>
                    {children}
                </Fragment>
            )}
        </div>
    );
}

export default function Support(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const orga = JSON.parse(sessionStorage.getItem(BINTU_ORGA));
    const canGetOrga = usePermission(permissionConfig.permissionMasks.organisation.get);

    document.title = "Help Center | nanoStream Cloud Dashboard";

    const handleOpenExternal = (link) => () => { window.open(link, "_blank") }
    const handleLinkClicked = (link) => () => { navigate(link); }
    const handleUpdateIndex = (event, newIndex) => { setIndex(newIndex); }

    const handleGetSupport = () => {
        let supportUrl = `${NANO_SUPPORT}?bintu.id=${orga ? orga.id : ""}&bintu.name=${orga ? orga.name : ""}&bintu.webhook=${orga ? orga.webhook : ""}&bintu.vod=${orga ? orga.vod : ""}&bintu.webrtc=${orga ? orga.webrtc : ""}&bintu.transcoding=${orga ? orga.transcoding : ""}&bintu.h5live=${orga ? orga.h5live : ""}&bintu.hash=${orga ? orga.hash : ""}&bintu.hls=${orga ? orga.hls : ""}&bintu.secure=${orga ? orga.secure : ""}&bintu.metrics=${orga ? orga.metrics : ""}&bintu.sla=${orga ? orga.sla : ""}&bintu.trial=${orga ? orga.trial : ""}`
        window.open(supportUrl, "_blank")
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <SectionHeader
                    title="Help Center"
                    underline="Let's turn your question marks into exclamation marks."
                />
            </Grid>
            <Grid item xs={12}>
                <Tabs value={index} onChange={handleUpdateIndex}>
                    <Tab label="Useful Resources" />
                    <Tab label="Getting Started" />
                </Tabs>
                <Divider sx={{ mb: 2 }} />

                <TabPanel value={index} index={0}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8} sx={{ order: { xs: 1, md: 0 } }}>
                            <Grid container spacing={2}>
                                {
                                    Object.values(SUPPORT).map((content, index) => (
                                        <Grid item xs={12} sm={6} lg={4}>
                                            <Box
                                                sx={{
                                                    borderRadius: 1, borderTop: 10, p: 2,
                                                    borderColor: theme.palette.primary.main,
                                                    background: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],

                                                }}
                                            >
                                                <Avatar sx={{
                                                    m: `0 auto`, boxShadow: theme.shadows[5],
                                                    bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
                                                    color: theme.palette.primary.main
                                                }}>
                                                    {content.icon}
                                                </Avatar>
                                                <Typography
                                                    variant="body1" color="primary"
                                                    sx={{ fontFamily: 'Cocogoose', fontWeight: 600, width: '50%', textAlign: 'center', margin: '0 auto', my: 2, lineHeight: 1 }}
                                                >
                                                    {content.title}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                                                    {content.underline}
                                                </Typography>
                                                {
                                                    content.links?.map((l, i) => (
                                                        <Accordion key={i}>
                                                            <AccordionSummary expandIcon={<ExpandMore fontSize="inherit" />}>
                                                                <Typography variant="body2" color="textSecondary">
                                                                    {l.title}
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Button
                                                                    variant="contained" size="small"
                                                                    endIcon={<OpenInNew />}
                                                                    onClick={handleOpenExternal(l.link)}
                                                                >
                                                                    Visit documentation
                                                                </Button>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))
                                                }
                                                <Button sx={{ mt: 2 }}
                                                    variant="contained" size="small"
                                                    endIcon={<OpenInNew />}
                                                    onClick={handleOpenExternal(content.link)}
                                                >
                                                    Read more
                                                </Button>
                                            </Box>
                                        </Grid>

                                    ))
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ order: { xs: 0, md: 1 } }}>
                            <Box p={2} sx={{ bgcolor: alpha(theme.palette.primary.main, 0.3), borderRadius: 2 }}>
                                <SectionHeader
                                    title="Support from nanocosmos"
                                    underline="Get support in implementing anything you want to achieve within the nanoStream Cloud."
                                />
                                {
                                    canGetOrga &&
                                    <Box my={1} p={2} sx={{ bgcolor: alpha(theme.palette.primary.main, 0.5), borderRadius: 1 }}>
                                        <Typography variant="body2" sx={{ color: theme.palette.common.white, textTransform: 'uppercase', fontWeight: 700 }}>
                                            Your support level
                                        </Typography>
                                        <Typography variant="h3" sx={{ color: theme.palette.common.white, fontFamily: 'Cocogoose', fontWeight: 600 }}>
                                            {orga.sla ? orga.sla : "Basic"}
                                        </Typography>
                                    </Box>
                                }
                                <Button
                                    variant="contained"
                                    endIcon={<OpenInNew />}
                                    onClick={handleGetSupport}
                                >
                                    Get Support
                                </Button>
                                <Divider sx={{ my: 2 }} />
                                <Typography variant="body1" color="textSecondary" sx={{ fontStyle: 'italic' }} gutterBottom>
                                    Follow us to stay up to date!
                                </Typography>
                                <Stack direction="row" useFlexGap flexWrap={"wrap"} justifyContent={"flex-start"}>
                                    {
                                        SOCIAL_MEDIA.map((s, i) => (
                                            <IconButton key={i}
                                                color="primary" size="medium"
                                                onClick={handleOpenExternal(s.link)}
                                            >
                                                {s.icon}
                                            </IconButton>
                                        ))
                                    }
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={index} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} mt={2} mb={1}>
                            <SectionHeader
                                title="Getting started"
                                underline="To help you get started with the nanoStream Cloud, we've put together a range of helpful resources!"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <ArticleCard
                                image={streams}
                                title="How to setup your live stream"
                                underline="Watch this tutorial to learn how to create and start a stream. Our expert will guide you through this process within a few clicks."
                            >
                                <Button
                                    variant="outlined"
                                    size="small"
                                    endIcon={<PlayCircleOutline />}
                                    onClick={handleOpenExternal(GETTING_STARTED_CREATE_STREAM)}
                                >
                                    Watch Video
                                </Button>
                            </ArticleCard>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <ArticleCard
                                image={dashboard}
                                title="Getting Started with the Dashboard"
                                underline="Have a look into our docs to learn how to use the nanoStream Cloud Dashboard."
                            >
                                <Button
                                    variant="outlined"
                                    size="small"
                                    endIcon={<OpenInNew />}
                                    onClick={handleOpenExternal(GETTING_STARTED_CREATE_STREAM)}
                                >
                                    Learn more
                                </Button>
                            </ArticleCard>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <ArticleCard
                                image={datagrid}
                                title="Analytics for your streams"
                                underline="Know how your streams are performing, and analyse the data at the tip of your fingers! Experience for yourself how it can help you."
                            >
                                <Button
                                    variant="outlined"
                                    size="small"
                                    endIcon={<PieChartOutline />}
                                    onClick={handleOpenExternal(METRICS_DOCS)}
                                >
                                    Read more
                                </Button>
                            </ArticleCard>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <ArticleCard
                                image={world}
                                title="Learn more"
                                underline="You can find an overview of further links to each component of the nanoStream Cloud here. These include the API, blog articles and samples."
                            >
                                <Button
                                    variant="outlined"
                                    size="small"
                                    endIcon={<HelpOutline />}
                                    onClick={handleLinkClicked(SUPPORT)}
                                >
                                    Help Center
                                </Button>
                            </ArticleCard>
                        </Grid>
                    </Grid>
                </TabPanel>
            </Grid>
        </Grid>
    )
}
