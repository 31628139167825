import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTheme } from '@mui/styles';
import { Divider, Grid, Typography } from '@mui/material';
import { OpenInNewOutlined } from '@mui/icons-material';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';

import { usePermission } from '../utils/helper/hooks';
import permissionConfig from '../utils/permissions/permission_config';

import SnackbarMessage from '../components/global/SnackbarMessage';
import CreateStream from '../components/webcaster/CreateStream';
import StreamOverview from '../components/stream/overview/StreamOverview';
import SectionHeader from '../components/global/SectionHeader';
import WebcasterVideo from '../components/webcaster/WebcasterVideo';
import NoAccess from '../components/global/NoAccess';


export default function Webcaster(props) {
    const theme = useTheme();
    const navigate = useNavigate();

    const canCreateStream = usePermission(permissionConfig.permissionMasks.stream.createStream);

    let { id } = useParams();
    const isIdInUrl = useMemo(() => !!id, [id]);

    const orga = JSON.parse(sessionStorage.getItem(ls.BINTU_ORGA));
    const h5liveToken = JSON.parse(sessionStorage.getItem(ls.H5LIVE_HASH_SECURE));

    const [showCreateStream, setShowCreateStream] = useState(false);
    const [stream, setStream] = useState(null);

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    document.title = "Webcaster | nanoStream Cloud Dashboard";

    const handleCloseError = (event, reason) => {
        setShowError(false);
        if (reason === 'clickaway') return;
    }

    const handleOpenLink = (link) => () => {
        window.open(link, '_target');
    }

    const getStream = () => {
        let data = { streamid: id }

        bintu.getStreamById(data)
            .then((response) => {
                if (response.success) {
                    let fetchedStream = response.data;
                    setShowCreateStream(false);
                    setStream(fetchedStream);
                }
            }).catch((error) => {
                setStream(false);
                setShowCreateStream(true);
                setError({ type: "warning", message: `The stream with the id ${id} could not be found.Please create a new stream by clicking on create new stream below.` });
                setShowError(true);
                navigate(`/webcaster`)
            })
    }

    const handleUpdateStream = (stream) => {
        setShowCreateStream(false);
        navigate(`/webcaster/${stream.id}`)
    }

    useEffect(() => {
        if (isIdInUrl) {
            getStream();
        } else {
            setStream(null);
            setShowCreateStream(true);
        }
    }, [isIdInUrl]);

    return (
        <Fragment>
            {
                !canCreateStream
                    ? <NoAccess content="nanoStream Webcaster" />
                    : <Grid container spacing={2}>
                        <SnackbarMessage
                            open={showError}
                            close={handleCloseError}
                            type={error.type}
                        >
                            <b>{error.code}</b> {error.message}
                        </SnackbarMessage>
                        <Grid item xs={12}>
                            <SectionHeader
                                title={`nanoStream Webcaster`}
                                underline="The Webcaster is based on WebRTC which works on all modern browsers. You can directly send a live stream to your audience from your live camera or screen!"
                                button="nanoStream Webcaster Docs"
                                interact
                                icon={<OpenInNewOutlined />}
                                clicked={handleOpenLink(link.WEBCASTER_INTRO)}
                            />
                        </Grid>
                        {
                            !showCreateStream && stream
                            &&
                            <Grid item xs={12}>
                                <WebcasterVideo stream={stream} />
                            </Grid>
                        }
                        {

                            showCreateStream &&
                            <Grid item xs={12}>
                                <Divider sx={{ mb: 1, mt: 0 }} />
                                <CreateStream newStream={handleUpdateStream} />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {

                                stream &&
                                <StreamOverview
                                    hideLockAndStop
                                    hideStreamUnderline
                                    showOverviewButton
                                    stream={stream}
                                    token={h5liveToken}
                                    secure={orga.secure}
                                    orga={orga}
                                    refresh={getStream}
                                />
                            }
                        </Grid>
                        <Grid item xs={12} md={6}>
                        </Grid>
                    </Grid>
            }
        </Fragment>
    )
}
