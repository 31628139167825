import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import * as link from '../utils/helper/link-config';
import * as m from '../utils/helper/methods';

import world from '../assets/world-background.jpeg';
import datagrid from '../assets/datagrid_background.jpeg';
import nsc_orange from '../assets/orange-nsc.png';

import { makeStyles } from '@mui/styles';
import { Grid, Typography, alpha, Collapse, IconButton, Button, Box, Stack, Chip, Hidden } from '@mui/material';
import { useTheme } from '@emotion/react';

import SignIn from '../components/registration/SignIn';
import SignUp from '../components/registration/SignUp';
import SnackbarMessage from '../components/global/SnackbarMessage';
import { CalendarMonthOutlined, CreditCardOff, CreditCardOffOutlined, HelpOutline, SwitchAccessShortcutAdd } from '@mui/icons-material';


export default function Auth(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [login, setLogin] = useState(true);
    const [socialMedia] = useState(link.SOCIAL_MEDIA);

    document.title = "Sign Up | nanoStream Cloud Dashboard";

    const changeAuthState = () => navigate(link.SIGNUP);

    const handleClickedSocialMediaLink = (link) => () => window.open(link);

    useEffect(() => {
        // TO DO: if has access, redirect to dashboard
        if (m.CHECK_ACCESS()) { navigate(link.DASHBOARD) }
    }, [])

    useEffect(() => {
        if (searchParams.has('signup')) navigate(link.SIGNUP)
    }, []);

    return (
        <Fragment>
            <Grid container p={2}
                sx={{
                    minHeight: '100vh',
                    height: '100%', maxHeight: '100%',
                    bgcolor: theme.palette.secondary.light,
                    // backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.secondary.light, 0.4)}, ${theme.palette.secondary.main})`,
                    backgroundImage: // theme.palette.mode === "light"
                        // ? `linear-gradient(to top, ${alpha(theme.palette.secondary.light, 0.6)}, ${alpha(theme.palette.secondary.light, 0.9)}), url(${world})` : 
                        `linear-gradient(to top, ${alpha(theme.palette.secondary.light, 0.8)}, ${alpha(theme.palette.secondary.dark, 0.9)}), url(${world})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "top",
                }}
            >
                <Grid item container xs={12} md={12} px={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={8} md={6} lg={5}
                        sx={{
                            color: theme.palette.common.white,
                            textAlign: 'center', borderRadius: theme.spacing(2),
                            p: 4, border: `2px solid ${theme.palette.info.main}`,
                            backgroundImage: `linear-gradient(to bottom, ${alpha(theme.palette.secondary.light, 0.4)}, ${theme.palette.secondary.main})`,
                            boxShadow: theme.shadows[23]
                        }}
                    >
                        <Typography variant="h3" mb={1} sx={{ fontFamily: 'Cocogoose', fontWeight: 400, lineHeight: 1 }}>
                            Welcome back to
                        </Typography>
                        <Box
                            component="img" src={nsc_orange} mt={.5}
                            sx={{ height: { xs: 50, md: 90 } }}
                        />
                        <Typography variant="h6" sx={{ my: 2, fontFamily: 'Cocogoose', fontWeight: 400, }}>
                            Sign in and continue streaming globally in <b>real-time</b>.
                        </Typography>
                        <SignIn signup={changeAuthState} />
                        <Stack direction="row" mt={3} useFlexGap flexWrap={"wrap"} justifyContent={"center"}>
                            {
                                socialMedia.map((s, i) => (
                                    <IconButton key={i}
                                        color="primary" size="medium"
                                        onClick={handleClickedSocialMediaLink(s.link)}
                                    >
                                        {s.icon}
                                    </IconButton>
                                ))
                            }
                        </Stack>
                        <Button
                            variant="outlined" size="small" sx={{ mt: 1 }}
                            endIcon={<HelpOutline />}
                            onClick={handleClickedSocialMediaLink(link.NANO_SUPPORT)}
                        >
                            Help Center
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment >
    )
}
