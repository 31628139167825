import React, { useState } from 'react';

import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';
import * as ls from '../../utils/helper/ls-vars';

import { makeStyles, useTheme } from '@mui/styles';
import { Button, Checkbox, Collapse, FormControlLabel, Grid, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { ArrowRightAlt, Lock, Mail, VpnKey } from '@mui/icons-material';
import SnackbarMessage from '../global/SnackbarMessage';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(4),
        }
    },
    header: {
        color: theme.palette.common.white
    },
    accent: {
        color: theme.palette.primary.main,
        fontWeight: 700
    },
    underline: {
        color: theme.palette.common.white,
        fontWeight: 400
    },
    textFieldWrapper: {
        margin: theme.spacing(2, 0)
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2)
    },
    textField: {
        "& .Mui-disabled": {
            color: `${theme.palette.common.white} !important`,
            "-webkit-text-fill-color": `${theme.palette.common.white} !important`,
            color: theme.palette.common.white
        },
        '& .NanoInput-underline:before': {
            borderBottomColor: theme.palette.common.white,
        },
    }
}));

export default function ResetPassword(props) {
    const classes = useStyles();
    const theme = useTheme();
    let mailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [textfields, setTextfields] = useState({
        inviteToken: {
            value: props.token,
            id: "inviteToken",
            autoComplete: "token",
            label: "Password token",
            type: "text",
            error: false,
            disabled: true,
            icon: <VpnKey style={{ color: '#fff' }} />
        },
        email: {
            value: "",
            id: "email",
            autoComplete: "email",
            label: "E-Mail",
            type: "text",
            error: false,
            icon: <Mail color="primary" />
        },
        password: {
            value: "",
            id: "password",
            autoComplete: "new-password",
            label: "Password",
            type: "password",
            error: false,
            icon: <Lock color="primary" />
        },
        confirmPassword: {
            value: "",
            id: "confirmPassword",
            autoComplete: "new-password",
            label: "Confirm Password",
            type: "password",
            error: false,
            icon: <Lock color="primary" />
        }
    });

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleUpdateTextfield = (id) => (event) => {
        let updatedValue = event.target.value
        setTextfields({
            ...textfields,
            [id]: {
                ...textfields[id],
                value: updatedValue
            }
        })
    }

    const handleSubmitTextfield = (event) => {
        event.preventDefault();
        handleResetPassword(event);
    }

    const handleAnyKeyPressed = (event) => {
        let code = event.charCode
        switch (code) {
            case 13: handleResetPassword(event); break;
            default: break;
        }
    }

    const credentialsAreInvalid = () => {
        let mailInvalid = !mailRegex.test(textfields.email.value)
        let passwordInvalid = (textfields.password.value === "") || (textfields.confirmPassword.value !== textfields.password.value);

        setTextfields({
            ...textfields,
            email: {
                ...textfields.email,
                error: mailInvalid
            },
            password: {
                ...textfields.password,
                error: passwordInvalid
            },
            confirmPassword: {
                ...textfields.password,
                error: passwordInvalid
            }
        });


        return mailInvalid || passwordInvalid;
    }


    const handleResetPassword = (event) => {
        event.preventDefault();

        if (credentialsAreInvalid()) return;

        let data = {
            email: textfields.email.value,
            password: textfields.password.value,
            token: props.token
        }
        // RESET PASSWORD TO NEW PASSWORD
        bintu.setPassword(data)
            .then((response) => {
                if (response.success) {
                    props.resetted();
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })

    }

    return (
        <Grid
            container
            className={classes.root}
            direction="row"
            alignItems="center"
            justifyContent="center"
        >
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12} md={8}>
                <Typography variant="h3" className={classes.header}>
                    Reset your password for <span className={classes.accent}>nanoStream Cloud</span>!
                </Typography>
                <Typography variant="body1" className={classes.underline}>
                    Choose a new password for your nanoStream Cloud account and continue streaming with ultra-low latency!
                </Typography>
                {/* </Grid>
            <Grid item xs={6}> */}
                <form autoComplete>
                    {
                        Object.values(textfields).map((textfield, i) => (
                            <div key={textfield.label} className={classes.textFieldWrapper}>
                                <TextField
                                    key={`${textfield.id}-${i}-signup`}
                                    className={classes.textField}
                                    focused fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {textfield.icon}
                                            </InputAdornment>
                                        )
                                    }}
                                    value={textfield.value}
                                    variant="standard"
                                    type={textfield.type}
                                    label={textfield.label}
                                    disabled={textfield.disabled}
                                    autoComplete={textfield.autoComplete}
                                    error={textfields[textfield.id].error}
                                    onKeyPress={handleAnyKeyPressed}
                                    onChange={handleUpdateTextfield(Object.keys(textfields)[i])}
                                    onSubmit={handleSubmitTextfield}
                                />
                            </div>
                        ))
                    }
                    <div className={classes.buttonWrapper}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={handleResetPassword}
                            endIcon={<ArrowRightAlt />}
                        >
                            Set new password
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={props.resetted}
                        >
                            Back to Login
                        </Button>
                    </div>
                </form>
            </Grid>
        </Grid>

    )
}
