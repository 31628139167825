/* global BigInt */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';
import * as ls from '../../utils/helper/ls-vars';
import * as metrics from '../../analytics/utils/api-requests';

import { Button, Checkbox, Collapse, FormControlLabel, Grid, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import { ArrowRightAlt, Lock, Mail } from '@mui/icons-material';

import SnackbarMessage from '../global/SnackbarMessage';
import { DECODE_TOKEN } from '../../utils/helper/methods';
import permissionHelper from '../../utils/permissions/permission_utils';
import permissionConfig from '../../utils/permissions/permission_config';

const initialTextfields = {
    email: {
        value: "",
        id: "email",
        autoComplete: "email",
        label: "E-Mail",
        type: "text",
        error: false,
        icon: <Mail color="primary" />,
        show: true
    },

    password: {
        value: "",
        id: "password",
        autoComplete: "password",
        label: "Password",
        type: "password",
        error: false,
        icon: <Lock color="primary" />,
        show: true
    },
};

export default function SignIn(props) {
    const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [forgotPassword, setForgotPassword] = useState(false);
    const [textfields, setTextfields] = useState(initialTextfields);
    const navigate = useNavigate();

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') return;

        setShowError(false);
    }

    const handleUpdateTextfield = (id) => (event) => {
        setTextfields({
            ...textfields,
            [id]: { ...textfields[id], value: event.target.value }
        });
    };

    const handleAcceptTerms = () => {
        setCheckedTerms(!checkedTerms);
        if (!checkedTerms && showError) setShowError(false);
    }

    const handleSubmitTextfield = (event) => {
        event.preventDefault();
        handleLogin(event);
    }

    const handleAnyKeyPressed = (event) => {
        let code = event.charCode
        switch (code) {
            case 13: handleLogin(event); break;
            default: break;
        }
    }

    const validateCredentials = () => {
        const mailInvalid = !mailRegex.test(textfields.email.value);
        const passwordInvalid = !forgotPassword && textfields.password.value === "";

        setTextfields({
            ...textfields,
            email: { ...textfields.email, error: mailInvalid },
            ...(forgotPassword ? {} : { password: { ...textfields.password, error: passwordInvalid } })
        });

        if (!forgotPassword && !checkedTerms) {
            setError({ type: "info", message: "To use the nanoStream Cloud dashboard, you need to accept our terms and conditions." });
            setShowError(true);
        }

        return mailInvalid || passwordInvalid || (!forgotPassword && !checkedTerms);
    }

    const resetPassword = () => {
        const data = { email: textfields.email.value };
        bintu.resetPassword(data)
            .then((response) => {
                if (response.success) handleForgotPassword();
            })
            .catch((error) => {
                setError(error);
                setShowError(true);
            });
    };


    const successfulLogin = () => {
        window.dispatchEvent(new Event("storage")); // Register storage changes
        navigate(link.DASHBOARD); // Redirect to Dashboard
    }

    const handleLogin = (event) => {
        event.preventDefault();
        if (validateCredentials()) return;

        if (forgotPassword) {
            resetPassword();
            return;
        }

        // TO DO LOGIN USER
        let userCredentials = {
            email: textfields.email.value,
            password: textfields.password.value
        }

        localStorage.clear();

        bintu.validate(userCredentials)
            .then((response) => {
                if (response.success) {
                    let data = response.data;
                    let token = data.token;
                    let decodedToken = DECODE_TOKEN(token);
                    const grantedPermissions = decodedToken ? BigInt(decodedToken?.permissions?.bintu) : 0n;

                    sessionStorage.setItem(ls.AUTHORIZED, true);
                    sessionStorage.setItem(ls.BINTU_TOKEN, token);
                    sessionStorage.setItem(ls.ROLE, data.role);
                    sessionStorage.setItem(ls.BINTU_MAIL, textfields.email.value);

                    if (data.apiKey) sessionStorage.setItem(ls.BINTU_APIKEY, data.apiKey);

                    // get metric key
                    let hasMetricPermission = permissionHelper.checkPermission(permissionConfig.permissionMasks.metricKey.get, grantedPermissions);

                    if (hasMetricPermission) {
                        bintu.getMetricKey()
                            .then((data) => {
                                console.log(data);
                            }).catch((error) => {
                                setError(error);
                                setShowError(true);
                            })
                    }

                    bintu.getOrganisation(token)
                        .then((orgaResponse) => {
                            if (response.success) {
                                let res = orgaResponse.data
                                if (res.isEnabled) sessionStorage.setItem(ls.BINTU_ORGA, JSON.stringify(res));

                                if (!res.isEnabled) {
                                    setError({ type: 'warning', message: "Your orga has been disabled. Please contact our sales team via our contact form nanocosmos.de/contact." });
                                    setShowError(true);
                                    return;
                                }

                                if (res.secure && decodedToken?.aud.includes("splay")) {
                                    // TO DO ADD permission
                                    let exp = Math.round(moment(new Date(Date.now())).add(24, 'hours') / 1000);
                                    let data = {
                                        tag: "nanostream-cloud-dashboard-player-token",
                                        exp: exp, orgawide: true
                                    };
                                    bintu.createH5LiveSecureToken({ token, data })
                                        .then((secureResponse) => {
                                            if (response.success) {
                                                sessionStorage.setItem(ls.H5LIVE_HASH_SECURE, JSON.stringify(secureResponse.data.data.token));
                                            }
                                        }).catch((error) => {
                                            if (!error.success) {
                                                setError(error);
                                                setShowError(true);
                                            }
                                        });
                                }
                                successfulLogin();
                            }
                        }).catch((error) => {
                            setError(error);
                            setShowError(true);
                        });
                }
            }).catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleForgotPassword = () => {
        setForgotPassword(!forgotPassword);
        setTextfields({
            ...textfields,
            password: { ...textfields.password, show: !textfields.password.show }
        });
    };

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
        >
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={error.type ? error.type : "error"}
            >
                <b>{error.type ? "" : "Error:"} {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <form autoComplete>
                        {
                            Object.values(textfields).map((textfield, i) => {
                                if (forgotPassword && textfield.id === "password") return
                                return (
                                    <TextField
                                        key={`${textfield.id}-${i}-signin`}
                                        focused
                                        fullWidth
                                        sx={{ minBlockSize: 2, input: { color: `#fff` } }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {textfield.icon}
                                                </InputAdornment>
                                            )
                                        }}
                                        variant="standard"
                                        type={textfield.type}
                                        label={textfield.label}
                                        autoComplete={textfield.autoComplete}
                                        error={textfields[textfield.id].error}
                                        onKeyPress={handleAnyKeyPressed}
                                        onChange={handleUpdateTextfield(Object.keys(textfields)[i])}
                                        onSubmit={handleSubmitTextfield}
                                    />
                                )
                            })
                        }
                    </form>
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Stack direction="row" justifyContent={"space-between"} alignItems='center'>
                        <FormControlLabel
                            control={<Checkbox sx={{ color: '#fff' }} color="primary" name="acceptTerms" />}
                            label={
                                <Typography variant="overline" color="white">
                                    Accept <Link underline="hover" href={link.IMPRINT} target="_blank">Terms and Conditions</Link>
                                </Typography>
                            }
                            checked={checkedTerms}
                            onChange={handleAcceptTerms}
                        />
                        <Typography variant="overline" color="white" onClick={handleForgotPassword}>
                            {forgotPassword ? "Remember your password?" : "Forgot password?"}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent={"space-between"}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={handleLogin}
                            endIcon={<ArrowRightAlt />}
                        >
                            {forgotPassword ? "Reset" : "Login"}
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={props.signup}
                        >
                            Sign Up
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )
}
