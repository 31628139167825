import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Collapse, Grid, Icon, IconButton, Link, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import SectionContainer from '../../global/SectionContainer';
import { LIVE_PROCESSING } from '../../../utils/helper/nanostream-cloud';
import { Cached, CloudSync, CloudSyncOutlined, ContentCut, CopyAll, DownloadingOutlined, HideImage, HideImageOutlined, HighlightOff, InfoOutlined, InfoRounded, KeyboardArrowDown, KeyboardArrowUp, Loop, OpenInNew, PermMedia, PermMediaOutlined, Refresh, Replay, SwitchAccessShortcutAdd, TaskAltOutlined } from '@mui/icons-material';
import CopyButton from '../../global/CopyButton';
import Loading from '../../global/Loading';
import { TabContext, TabList } from '@mui/lab';
import moment from 'moment';
import SectionHeader from '../../global/SectionHeader';
import State from '../../global/State';
import { BINTU_ORGA, H5LIVE_HASH_SECURE } from '../../../utils/helper/ls-vars';
import { BINTU_API, CLIPPING_WEBDEMO, REPLAYER_WEBDEMO } from '../../../utils/helper/link-config';

const TabContent = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Box
            hidden={value !== index}
            {...other}
        >
            {value === index && <Fragment>{children}</Fragment>}
        </Box>
    );
}

const ImageNotAvailable = (props) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const { title, underline, notAvailable, children, onRefresh } = props;

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false)
            }, 600)
        }

    }, [loading])

    return (
        <SectionContainer
            noMargin title={title} underline={underline} clicked={onRefresh}
            interact button={`Refresh ${title}`} icon={<CloudSyncOutlined />}
        >
            {
                notAvailable
                    ?
                    <Stack
                        direction={"column"} alignItems={"center"} justifyContent={"center"}
                        sx={{ bgcolor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800], my: 1, py: 1, borderRadius: theme.spacing(1) }}
                    >
                        <Fragment>

                            {
                                loading
                                    ? <Loading />
                                    :
                                    <Icon size="large">
                                        <HideImageOutlined color="disabled" />
                                    </Icon>
                            }
                            <Typography variant="h6" sx={{ color: theme.palette.text.disabled }}>
                                {loading ? `Fetching ${title}` : `${title} not available`}
                            </Typography>
                        </Fragment>
                    </Stack>
                    : children

            }
        </SectionContainer >
    )
}

const TableCellTitle = (props) => {
    const { title, info } = props;
    return (
        <TableCell textAlign="center">
            <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
                <Typography variant="button" color="textSecondary" sx={{ lineHeight: 1 }}>
                    {title}
                </Typography>
                {
                    info &&
                    <IconButton size="small" onClick={() => window.open(info, '_blank')}>
                        <InfoOutlined fontSize="small" color="disabled" />
                    </IconButton>
                }
            </Stack>
        </TableCell>
    )
}

const Links = (props) => {
    const { opcode, streamgroup, stream, links } = props;
    const theme = useTheme();
    const orga = JSON.parse(sessionStorage.getItem(BINTU_ORGA));
    const h5liveToken = orga?.secure ? JSON.parse(sessionStorage.getItem(H5LIVE_HASH_SECURE)) : false;
    const isReplay = opcode.id === "replay";
    const isRec = opcode.id === "rec";
    const filteredData = isReplay
        ? links.filter(t => t.type === "playlist")
        : isRec
            ? streamgroup.playout.web
                .filter((web, i) => web.type === "vod")
                .filter((s, i) => s.streamId === stream.id)
            : links;

    return (
        <Fragment>
            {
                isReplay &&
                <SectionContainer
                    contrast small title="Latest Live Session" info
                    underline="Utilize the Live Replay Demo to create custom clips of the latest session."
                >
                    <Stack direction="row" flexWrap={"wrap"} useFlexGap spacing={.5} sx={{ mt: 1 }}>
                        {
                            links
                                .filter(t => t.type === "playout")
                                .map((d, i) => {

                                    return (
                                        <Button
                                            size="small" color="info" variant="contained"
                                            endIcon={<OpenInNew />} onClick={() => window.open(`${d.url}&bintu=${BINTU_API}${h5liveToken ? `&jwtoken=${h5liveToken}` : ""}`, '_target')}
                                        >
                                            Clip & Share Latest Live Session
                                        </Button>
                                    )
                                })
                        }
                    </Stack>
                </SectionContainer>
            }
            <TableContainer sx={{ mt: 2, maxHeight: 375, overflow: 'scroll' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {!isReplay && <TableCellTitle title="Type" />}
                            {(isRec || isReplay) && <TableCellTitle title="Session" />}
                            <TableCellTitle title={isReplay ? "HLS URL" : "URL"} />
                            {isReplay &&
                                <Fragment>
                                    <TableCellTitle title="Session Replay" />
                                    <TableCellTitle title="Replay Editor" />
                                </Fragment>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filteredData.map((d, i) => {
                                let fileName = d.url.split("/").pop();
                                let sessionType = d.url.split("/").pop();
                                let date = d?.createdAt
                                    ? moment(d?.createdAt).utc().format('MM/DD/YYYY hh:mm A (UTC)')
                                    : "Latest";

                                if (isReplay) {
                                    let sessionWithoutType = sessionType.split(".")[0];
                                    let cutSessionType = sessionWithoutType?.split("-");
                                    if (cutSessionType.length < 3) sessionType = false;
                                    if (cutSessionType.length === 3) sessionType = cutSessionType?.pop();
                                }

                                return (
                                    <TableRow key={`${d.url}-${i}`} hover>
                                        {!isReplay && <TableCellTitle title={d.type} />}
                                        {(isRec || isReplay) && <TableCellTitle title={date} />}
                                        <TableCell>
                                            <Stack direction="row" spacing={1} alignItems={"center"}>
                                                <Typography variant="body2">
                                                    {isReplay && fileName}
                                                    {
                                                        !isReplay &&
                                                        <Link href={d.url} underline="always" target="_blank">
                                                            {fileName}
                                                        </Link>
                                                    }
                                                    {(isRec && d.size) && ` (${(d.size / (1024 * 1024)).toFixed(2)} MB)`}
                                                </Typography>
                                                <CopyButton iconButton copy={d.url} />
                                            </Stack>
                                        </TableCell>
                                        {
                                            isReplay &&
                                            <Fragment>
                                                <TableCell>
                                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                                        <Button
                                                            onClick={() => window.open(`${REPLAYER_WEBDEMO}/?streamname=${d.streamname}${sessionType ? `&session=${sessionType}` : ""}`, "_blank")}
                                                            variant="contained" color="primary"
                                                            size="small" startIcon={<Replay />}
                                                        >
                                                            Watch Replay
                                                        </Button>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>
                                                    <Stack direction="row" spacing={1} alignItems={"center"}>
                                                        <Button
                                                            onClick={() => window.open(`${CLIPPING_WEBDEMO}/?streamname=${d.streamname}${sessionType ? `&session=${sessionType}` : ""}`, "_blank")}
                                                            variant="contained" color="primary"
                                                            size="small" startIcon={<ContentCut />}
                                                        >
                                                            Clip & Share
                                                        </Button>
                                                    </Stack>
                                                </TableCell>
                                            </Fragment>
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment >
    )
}

const ProcessingRow = (props) => {
    const { opcodeId: id, stream, streamgroup } = props;
    let opcode = LIVE_PROCESSING[id];
    const process = stream?.processing?.find(p => p.id === opcode.id);
    const hasProcess = process ? true : false;
    const isRec = process?.id === "rec";
    const isReplay = process?.id === "replay";

    let opcodeId = opcode.id === "thumbs" ? "thumbnails" : opcode.id;
    const links = streamgroup.playout[opcodeId];
    const linksForStream = links?.filter(l => l.streamname === stream.streamname) || [];

    const [open, setOpen] = useState(false);

    const openLinks = () => { setOpen(!open) }


    return (
        <Fragment>
            <TableRow hover onClick={openLinks} sx={{ cursor: 'pointer' }}>
                <TableCell>
                    <IconButton size="small" onClick={openLinks}>
                        {open ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" />}
                    </IconButton>
                </TableCell>
                <TableCellTitle info={opcode.docs} title={opcode.title} />
                <TableCell>
                    <Icon>
                        {
                            hasProcess
                                ? <TaskAltOutlined color="primary" fontSize="small" />
                                : <HighlightOff color="disabled" fontSize="small" />

                        }
                    </Icon>
                </TableCell>
                {
                    hasProcess && process.hasOwnProperty("interval")
                        ? <TableCellTitle title={process?.interval} />
                        : <TableCell><HighlightOff color="disabled" fontSize="small" /></TableCell>
                }
                {
                    hasProcess && process.hasOwnProperty("duration")
                        ? <TableCellTitle title={process?.duration ? process?.duration : "Entire Stream"} />
                        : <TableCell><HighlightOff color="disabled" fontSize="small" /></TableCell>
                }
                <TableCell>
                    {isRec ? streamgroup.playout.web.filter((s, i) => s.streamId === stream.id).filter((web, i) => web.type === "vod").length : isReplay ? Math.max(linksForStream.length - 1, 0) : linksForStream.length}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{ py: open ? 2 : 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <SectionContainer
                            noMargin small title={`${opcode.title}`}
                            underline={opcode.underline}
                        >
                            <Links
                                opcode={opcode} links={linksForStream || []}
                                stream={stream} streamgroup={streamgroup}
                            />
                        </SectionContainer>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    )
}

const Details = (props) => {
    const { singleStream, streamgroup, label } = props;
    const orga = sessionStorage.getItem(BINTU_ORGA) ? JSON.parse(sessionStorage.getItem(BINTU_ORGA)) : null;
    const allowedOperations = orga?.allowedOpcodes ? orga.allowedOpcodes : [];

    return (
        <SectionContainer
            contrast small
            title={`Live Processing Details`}
            underline={`Showing ${label} stream with name ${singleStream.streamname}.`}
        >
            <TableContainer sx={{ mt: 1 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} />
                            <TableCellTitle title="Is Active" />
                            <TableCellTitle title="Interval (s)" />
                            <TableCellTitle title="Duration (s)" />
                            <TableCellTitle title="Available Links" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.keys(LIVE_PROCESSING)
                                .filter(key => allowedOperations.includes(key))
                                .map((o, i) => (
                                    <ProcessingRow
                                        streamgroup={streamgroup} key={singleStream.id}
                                        stream={singleStream} opcodeId={o}
                                    />
                                ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </SectionContainer >
    )
}


export default function LiveProcessingOverview(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { stream, handleClicked, previewOnly, refresh } = props;

    const [noThumbnail, setNoThumbnail] = useState(false);
    const [noMotionclip, setNoMotionclip] = useState(false);

    const findFirst = (opcodeId, key) => {
        const streamWithKey = stream.playout.rtmp?.find(s => s.processing?.some(opcode => opcode.id === opcodeId));

        if (streamWithKey) {
            const resource = stream.playout[key]?.find(res => res.streamId === streamWithKey.id);
            if (resource) return resource.url;
        }
        return "";
    };

    const [tabIndex, setTabIndex] = useState(0);
    const [thumbnailUrl, setThumbnailUrl] = useState(findFirst(LIVE_PROCESSING.thumbs.id, 'thumbnails'));
    const [motionclipUrl, setMotionclipUrl] = useState(findFirst(LIVE_PROCESSING.motionclip.id, 'motionclip'));

    const handleTabbing = (event, newValue) => { setTabIndex(newValue); };

    const handleThumbnailError = (value) => (err) => { setNoThumbnail(value); }
    const handleMotionclipError = (value) => (err) => { setNoMotionclip(value); }

    const handleRefreshThumbnail = () => setThumbnailUrl(`${findFirst(LIVE_PROCESSING.thumbs.id, 'thumbnails')}?timestamp=${new Date().getTime()}`);
    const handleRefreshMotionclip = () => setMotionclipUrl(`${findFirst(LIVE_PROCESSING.motionclip.id, 'motionclip')}?timestamp=${new Date().getTime()}`);

    useEffect(() => {
        handleRefreshThumbnail();
        handleRefreshMotionclip();

        setNoThumbnail(false);
        setNoMotionclip(false);
    }, [stream])


    return (
        <Grid container spacing={2}>
            {
                stream.playout?.thumbnails &&
                <Grid item xs={12} sm={6}>
                    <ImageNotAvailable
                        title={"Thumbnail"} notAvailable={noThumbnail} onRefresh={refresh}
                        underline={"The thumbnail for the ongoing stream is captured based on the set interval. Refresh the page to update the preview."}
                    >
                        <Box
                            component="img" alt="Thumbnail Preview"
                            sx={{ height: '100%', width: '100%', mt: 1, borderRadius: theme.spacing(.5) }}
                            src={thumbnailUrl} onError={handleThumbnailError(true)}
                        />
                    </ImageNotAvailable>
                </Grid>
            }
            {
                stream.playout?.motionclip &&
                <Grid item xs={12} sm={6}>
                    <ImageNotAvailable
                        title={"Motion Clip"} notAvailable={noMotionclip} onRefresh={refresh}
                        underline={"The motionclip for the ongoing stream is captured based on the set interval. Refresh the page to update the preview."}
                    >
                        <Box
                            component="img" alt="Motionclip Preview"
                            sx={{ height: '100%', width: '100%', mt: 1, borderRadius: theme.spacing(.5) }}
                            src={motionclipUrl} onError={handleMotionclipError(true)}
                        />
                    </ImageNotAvailable>
                </Grid>
            }
            {
                !previewOnly &&
                <Grid item xs={12}>
                    <SectionContainer noMargin>
                        <Stack direction="row" spacing={1} alignItems={"center"} sx={{ mb: .5 }}>
                            <Typography color="primary" variant={"h4"} sx={{ lineHeight: 1.1, fontWeight: 700 }}>
                                Live Processing
                            </Typography>
                            <Button
                                size="small" variant="contained" color="primary"
                                endIcon={<SwitchAccessShortcutAdd />} onClick={handleClicked}
                                sx={{ lineHeight: 1.2, py: 1, px: 1.5 }}
                            >
                                Edit Options
                            </Button>
                            <Button
                                size="small" variant="outlined" color="primary"
                                endIcon={<Refresh />} onClick={refresh}
                                sx={{ lineHeight: 1.2, py: 1, px: 1.5 }}
                            >
                                Refresh
                            </Button>
                        </Stack>
                        <Typography variant={"body1"} color="textSecondary" sx={{ lineHeight: 1.3 }}>
                            Overview of enabled processing features and their respective settings, designed to enhance playback and content management.
                        </Typography>
                        <TabContext value={tabIndex}>
                            {
                                stream.playout.rtmp?.length > 1 &&
                                <TabList onChange={handleTabbing}>
                                    {
                                        stream.playout.rtmp?.map((s, i) => {
                                            let label = s.index === 0 ? "Passthrough" : `${s.index}. Transcode`;
                                            return (<Tab key={i} label={label} value={i} />)
                                        })
                                    }
                                </TabList>
                            }
                            {
                                stream.playout.rtmp?.map((s, i) => {
                                    let label = s.index === 0 ? "Passthrough" : `${s.index}. Transcode`;
                                    let streamname = s.streamname

                                    return (
                                        <TabContent key={`${streamname}-${i}`} value={tabIndex} index={i}>
                                            <Details streamgroup={stream} singleStream={s} label={label} />
                                        </TabContent>
                                    )
                                })
                            }
                        </TabContext>
                    </SectionContainer>
                </Grid>
            }
        </Grid>
    )
}
