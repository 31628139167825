import React, { useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { TextField, Grid } from '@mui/material';

import SectionContainer from '../global/SectionContainer';
import SectionHeader from '../global/SectionHeader';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function AddMetadata(props) {
    const classes = useStyles();
    const [timecode, setTimecode] = useState(2000);

    const handleUpdateTextfield = (event) => {
        setTimecode(event.target.value);
    }

    const handleBlurredTextfield = () => {
        if (timecode === "" || Number(timecode) < 500 || Number(timecode) > 3600000 || /[^0-9]+/.test(timecode)) {
            setTimecode(500)
        }
    }

    useEffect(() => {
        props.updateTimecode(Number(timecode));
    }, [timecode])

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <SectionContainer
                    title="Set timecode injection period"
                    underline="You can set the time injection interval between 500 ms and 3600000 ms (1 hour), recommended: 1000-5000 ms."
                >
                    {
                        !props.hideHeader &&
                        <SectionHeader
                            title="Set timecode injection period"
                            underline="You can set the time injection interval between 500 ms and 3600000 ms (1 hour), recommended: 1000-5000 ms."
                        />
                    }
                    <TextField
                        variant="standard"
                        label="Timecode (ms)"
                        value={timecode}
                        onBlur={handleBlurredTextfield}
                        onChange={handleUpdateTextfield}
                    />
                </SectionContainer>
            </Grid>
        </Grid>

    )
}
