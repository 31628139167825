import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/styles';
import { Grid, Box, TableContainer, Table, TableHead, TableRow, TableCell, Typography, TableBody, Stack, IconButton, Tooltip, Collapse, Button, Chip, alpha } from '@mui/material';
import { Delete, DeleteOutline, EditOutlined, Expand, ExpandMore, KeyboardArrowDown, KeyboardArrowUp, MoreHoriz, MoreVert, PersonAddAlt1Outlined, Refresh } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

import { BINTU_MAIL } from '../../utils/helper/ls-vars';
import { deleteUser, getPermissions, getUserList, renewToken } from '../../utils/bintu/api-requests';
import { ACCESS_LEVEL_NANO } from '../../utils/helper/nanostream-cloud';

import SectionHeader from '../global/SectionHeader';
import ContentTable from '../global/ContentTable';
import InviteNewUser from './InviteNewUser';
import ContentDialog from '../global/ContentDialog';
import EditUser from './EditUser';
import { usePermission } from '../../utils/helper/hooks';
import permissionConfig from '../../utils/permissions/permission_config';
import UserRoleIntroBanner from '../global/UserRoleIntroBanner';

export default function User(props) {
    const theme = useTheme();
    const navigate = useNavigate();

    const you = sessionStorage.getItem(BINTU_MAIL);
    const [user, setUser] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [openUser, setOpenUser] = useState("");
    const [update, setUpdate] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [refreshId, setRefreshId] = useState("");
    const [editId, setEditId] = useState("");

    const canRefreshToken = usePermission(permissionConfig.permissionMasks.user.renewUserTokenKey);
    const canGetUser = usePermission(permissionConfig.permissionMasks.user.getUser);
    const canChangeUserPermission = usePermission(permissionConfig.permissionMasks.user.changeUserPermissions);
    const canDeleteUser = usePermission(permissionConfig.permissionMasks.user.deleteUser);
    const canInvite = usePermission(permissionConfig.permissionMasks.inviteToken.create);
    const handleCreateNewToken = () => { setUpdate(!update); }

    const fetchPermissions = () => {
        getPermissions()
            .then((response) => {
                if (response.success) {
                    setPermissions(Object.values(response.data))
                }
            }).catch((error) => {
                console.log("Error fetching permissions:", error)
            })
    }

    const getUser = () => {
        getUserList()
            .then((response) => {
                if (response.success) {
                    setUser(response.data);
                }
            }).catch((error) => {
                if (!error.success) {
                    console.log("Error fetching User", error)
                }
            })
    }

    const handleDeleteUser = (id) => () => {
        if (deleteId === id) setDeleteId("");
        else setDeleteId(id);
    }

    const handleDelete = (toDelete) => () => {
        if (!toDelete) { setDeleteId(""); }
        else {
            let data = { userId: toDelete }
            deleteUser(data)
                .then((response) => {
                    if (response.success) {
                        setDeleteId("");
                        getUser();
                    }
                }).catch((error) => {
                    console.log("Error deleting User:", error)
                })
        }
    }

    const handleInfo = (value) => {
        console.log("hi", value)
        window.open("https://docs.nanocosmos.de/docs/user_roles#user-roles-in-nanostream-cloud", "_blank")
    }


    const handleRefreshClicked = (id) => () => {
        if (refreshId === id) setRefreshId("");
        else setRefreshId(id);
    }

    const handleRenewToken = (toRenew) => () => {
        if (!toRenew) { setRefreshId(""); }
        else {
            let data = { user: toRenew }
            renewToken(data)
                .then((response) => {
                    if (response.success) {
                        setRefreshId("");
                        getUser();
                    }
                }).catch((error) => {
                    console.log("Error refreshing Token:", error)
                })
        }
    }

    useEffect(() => {
        getUser();
        fetchPermissions();
    }, [])

    return (
        <Grid container>
            <InviteNewUser
                open={update}
                handleClose={handleCreateNewToken}
            />
            <Grid item xs={12}>
                <Box p={2} sx={{ borderRadius: theme.spacing(2), bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900] }}>
                    <SectionHeader
                        title={`All User (${user.length})`}
                        interact
                        icon={<PersonAddAlt1Outlined />}
                        clicked={handleCreateNewToken}
                        button={canInvite ? "Invite new user" : false}
                    />
                    <Box m={1} />
                    <UserRoleIntroBanner />
                    <TableContainer sx={{ maxHeight: { xs: 'auto', lg: 400 }, mt: 1, overflow: 'scroll' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {
                                        ["Email", "Permission", "Created at (UTC)", ""].map((th) => (
                                            <TableCell key={th}>
                                                <Typography variant="button" color="textSecondary">
                                                    {th}
                                                </Typography>
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    user.map((u, i) => {
                                        let accessLevel = ACCESS_LEVEL_NANO[u.permission];
                                        const own = you === u.email;
                                        const permissionObj = permissions.filter(p => p.name === u.permission)[0];

                                        return (
                                            <Fragment key={u.tokenKey}>
                                                <EditUser
                                                    user={u}
                                                    permissions={permissions}
                                                    open={editId === u.id}
                                                    refresh={getUser}
                                                    close={() => setEditId("")}
                                                />
                                                <ContentDialog
                                                    open={deleteId === u.id}
                                                    close={handleDelete(false)}
                                                    submit={handleDelete(u.id)}
                                                    submitButton={"Delete"}
                                                    title={`You are about to delete ${u.email} from your organization!`}
                                                    content={`Please confirm that you want to delete ${u.email} from your organization by clicking on "Delete".`}
                                                />
                                                <ContentDialog
                                                    open={refreshId === u.id}
                                                    close={handleRenewToken(false)}
                                                    submit={handleRenewToken(u.id)}
                                                    submitButton={"Confirm Reset"}
                                                    title={`Token Reset for ${u.email}`}
                                                    content={<>
                                                        You are about to reset the tokens for {u.email}. <br />
                                                        This action will <b>invalidate all existing bintu tokens for this user.</b> <br />
                                                        <i>Playback tokens issued by this user remain valid until they reach their expiration date.</i> <br />
                                                        Please confirm that you want to proceed by clicking on "Confirm Reset".
                                                    </>}
                                                />
                                                <TableRow
                                                    onClick={() => { setOpenUser(openUser === u.id ? null : u.id) }}
                                                    sx={{ cursor: 'pointer', '& > *': { borderBottom: 'unset' } }}
                                                >
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {u.email} {own && <b>(you)</b>}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            size="small" variant="outlined"
                                                            icon={accessLevel?.icon}
                                                            color={accessLevel?.color}
                                                            label={accessLevel?.title}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="subtitle2" color="textSecondary">
                                                            {moment(u.created_at).utc().format('MM/DD/YYYY hh:mm a')}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right" sx={{ borderBottom: 'unset' }}>
                                                        {
                                                            canGetUser &&
                                                            <Tooltip title="Expand">
                                                                <IconButton size="small">
                                                                    {
                                                                        openUser === u.id
                                                                            ? <KeyboardArrowUp fontSize="inherit" />
                                                                            : <KeyboardArrowDown fontSize="inherit" />
                                                                    }
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                                    <Collapse in={canGetUser && openUser === u.id}>
                                                        <Box sx={{ my: 1.5, p: 2, borderRadius: theme.spacing(1), bgcolor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800], }}>
                                                            <Typography variant="h6" color="primary" sx={{ fontWeight: 700 }}>
                                                                User Overview
                                                            </Typography>
                                                            <Typography variant="subtitle2" color="textSecondary">
                                                                {permissionObj?.description}
                                                            </Typography>
                                                            <ContentTable
                                                                data={[
                                                                    { label: "Email", value: u.email, copy: true },
                                                                    {
                                                                        label: "Permission",
                                                                        custom:
                                                                            <Chip
                                                                                size="small" variant="outlined"
                                                                                icon={accessLevel?.icon}
                                                                                color={accessLevel?.color}
                                                                                label={accessLevel?.title}
                                                                            />
                                                                    },
                                                                    { label: "Created at", date: moment(u.created_at).utc().format('MM/DD/YYYY hh:mm a (UTC)') },
                                                                    { label: "User Id", value: u.id, copy: true },
                                                                    { label: "Token Key", value: u.tokenKey, copy: true },
                                                                ]}
                                                            />
                                                            {
                                                                canChangeUserPermission &&
                                                                <Stack mt={1} direction="row" spacing={1}>
                                                                    <Button
                                                                        onClick={() => setEditId(u.id)}
                                                                        size="small" variant="contained"
                                                                        startIcon={<EditOutlined />}
                                                                    >
                                                                        Edit User
                                                                    </Button>
                                                                    {
                                                                        canDeleteUser && !own &&
                                                                        <Button
                                                                            onClick={handleDeleteUser(u.id)}
                                                                            size="small" variant="contained"
                                                                            startIcon={<DeleteOutline />}
                                                                        >
                                                                            Delete User
                                                                        </Button>
                                                                    }
                                                                    {
                                                                        canRefreshToken &&
                                                                        <Button
                                                                            onClick={handleRefreshClicked(u.id)}
                                                                            size="small" variant="contained"
                                                                            startIcon={<Refresh />}
                                                                        >
                                                                            Reset User Tokens
                                                                        </Button>
                                                                    }
                                                                </Stack>
                                                            }
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </Fragment>

                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Box>
            </Grid>
        </Grid >

    )
}
