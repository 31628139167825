import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import permissionConfig from '../utils/permissions/permission_config';
import { usePermission } from '../utils/helper/hooks';

import * as link from '../utils/helper/link-config';
import * as ls from '../utils/helper/ls-vars';
import * as bintu from '../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Collapse, Grid } from '@mui/material';
import { AddCircleOutline, OpenInNewOffOutlined, OpenInNewOutlined } from '@mui/icons-material';

import SectionHeader from '../components/global/SectionHeader';
import StreamCountOverview from '../components/dashboard/StreamCountOverview';
import LatestStreams from '../components/dashboard/LatestStreams';
import WelcomePage from '../components/dashboard/WelcomePage';
import SnackbarMessage from '../components/global/SnackbarMessage';
import MonthToDate from '../analytics/components/metrics/MonthToDate';
import UserRoleIntroBanner from '../components/global/UserRoleIntroBanner';

export default function Dashboard(props) {
    const navigate = useNavigate();
    const orga = JSON.parse(sessionStorage.getItem(ls.BINTU_ORGA));
    const canCreateStream = usePermission(permissionConfig.permissionMasks.stream.createStream);
    const canGetStreams = usePermission(permissionConfig.permissionMasks.stream.getStreams);

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const [streams, setStreams] = useState(false);
    const [loading, setLoading] = useState(true);
    const [streamCount, setStreamCount] = useState(15);
    const [streamFilter] = useState({
        offset: 0,
        quantity: 5,
    });

    document.title = "Dashboard | nanoStream Cloud Dashboard"

    const handleUpdateStreamCount = (count) => { setStreamCount(count); }
    const openInNew = (link) => () => { window.open(link, '_blank'); }
    const handleRedirect = (link) => () => { navigate(link); }

    const getStreams = () => {
        let data = {
            filter: streamFilter,
        }
        bintu.getStreamCollection(data)
            .then((response) => {
                if (response.success) {
                    setStreams(response.data)
                    setLoading(false)
                }
            }).catch((error) => {
                setStreams(false);
                setLoading(false);
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') return;
        setShowError(false);
    }

    useEffect(() => { if (canGetStreams) getStreams(); }, [])

    return (
        <Grid container>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <Grid item xs={12} sx={{ mb: 2 }}>
                <UserRoleIntroBanner />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={!loading && streamCount < 15}>
                    <WelcomePage orga={orga} />
                </Collapse>
                <SectionHeader
                    title="Overview"
                    underline={`Hi ${orga ? orga.name : ""}! Here's what's happening in the nanoStream Cloud. `}
                    button="Create new stream"
                    icon={<AddCircleOutline />}
                    clicked={handleRedirect(link.CREATE_STREAM)}
                    interact
                />
            </Grid>
            <Grid item xs={12} my={1}>
                <StreamCountOverview
                    getCreated
                    updateAmountOfStreams={handleUpdateStreamCount}
                    showAlertsRedirect
                />
            </Grid>
            <Grid item xs={12} sx={{ mt: 1 }}>
                {/* <SectionHeader
                    title="Your Usage"
                    underline={`Track your month-to-date total usage, playout and ingest to keep track of your nanoStream Cloud.`}
                    button="See all metrics"
                    icon={<OpenInNewOutlined />}
                    clicked={openInNew(link.METRICS_LOGIN)}
                    interact
                /> */}
                <MonthToDate />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={streams}>
                    <LatestStreams streams={streams} />
                </Collapse>
            </Grid>
        </Grid>

    )
}
