import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import * as analytics from '../utils/helper/analytics';
import * as link from '../utils/helper/link-config';
import * as m from '../utils/helper/methods';

import world from '../assets/world-background.png';
import cheetha from '../assets/orange-cheetha.png';
import nsc from '../assets/nsc.png';
import logo from '../assets/orange-logo.png'

import { makeStyles } from '@mui/styles';
import { Grid, Typography, alpha, IconButton } from '@mui/material';
import { Facebook, GitHub, Instagram, Language, LinkedIn, Twitter, YouTube } from '@mui/icons-material';

import ResetPassword from '../components/registration/ResetPassword';


const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
        minHeight: '100%',
        height: '100vh',
        maxHeight: '100%'
    },
    item: {
        '&:first-of-type': {
            background: theme.palette.secondary.main,
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        '&:last-of-type': {
            [theme.breakpoints.up('xs')]: {
                backgroundImage: `linear-gradient(to bottom, ${theme.palette.secondary.main}, ${alpha(theme.palette.secondary.main, 0.7)}),
                url(${world})`,
            },
            [theme.breakpoints.up('md')]: {
                backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.main}, ${alpha(theme.palette.common.white, 0.2)}),
        url(${world})`,
            },
            // backgroundImage: `url(${world})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center botttom",
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    },
    slogan: {
        [theme.breakpoints.down('md')]: {
            fontSize: '2rem !important'
        },
        fontWeight: 600,
        textAlign: 'center',
    },
    cheetha: {
        [theme.breakpoints.up('xs')]: {
            display: 'block',
            margin: '0 auto',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            width: 200,
            maxWidth: '100%',
        },
        [theme.breakpoints.up('md')]: {
            marginTop: theme.spacing(4),
            width: 400
        }
    },
    nsc: {
        [theme.breakpoints.up('xs')]: {
            display: 'block',
            margin: '0 auto',
            marginBottom: theme.spacing(2),
            maxWidth: '100%',
            width: 100
        },
        [theme.breakpoints.up('md')]: {
            width: 200,
            marginBottom: theme.spacing(4),
        }
    },
    logo: {
        height: 30,
    },
    socialMediaWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));

export default function SetPassword(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    let { token } = useParams();
    const [redirectRoute, setRedirectRoute] = useState(null);
    const [socialMedia] = useState([
        { icon: (<GitHub fontSize="inherit" />), link: link.GITHUB },
        { icon: (<Language fontSize="inherit" />), link: link.WEBSITE },
        { icon: (<Facebook fontSize="inherit" />), link: link.FACEBOOK },
        { icon: (<YouTube fontSize="inherit" />), link: link.YOUTUBE },
        { icon: (<Twitter fontSize="inherit" />), link: link.TWITTER },
        { icon: (<Instagram fontSize="inherit" />), link: link.INSTAGRAM },
        { icon: (<LinkedIn fontSize="inherit" />), link: link.LINKEDIN }
    ]);

    document.title = "Set new Password | nanoStream Cloud Dashboard";

    const changeAuthState = () => {
        navigate(`${link.AUTH}?login`);
    }

    const handleClickedSocialMediaLink = (link) => () => {
        window.open(link, '_blank')
    }

    useEffect(() => {
        analytics.SEND_GA_ANALYTICS(window.location.pathname)
    }, [])

    useEffect(() => {
        sessionStorage.clear();
        window.dispatchEvent(new Event("storage"));
    }, [])


    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} md={6} className={classes.item}>
                {/* NANO LOGO */}
                <div>
                    <img src={logo} className={classes.logo} />
                </div>
                <div>
                    {/* RESET PASSWORD */}
                    <ResetPassword
                        token={token}
                        resetted={changeAuthState}
                    />
                </div>
                <div className={classes.socialMediaWrapper}>
                    {
                        socialMedia.map((s, i) => (
                            <IconButton
                                key={i}
                                color="primary"
                                size="medium"
                                onClick={handleClickedSocialMediaLink(s.link)}
                            >
                                {s.icon}
                            </IconButton>
                        ))
                    }
                </div>
            </Grid>
            <Grid item xs={12} md={6} className={classes.item}>
                <img src={nsc} className={classes.nsc} />
                <Typography className={classes.slogan} variant="h3" color="primary">
                    Interactive<br />
                    streaming<br />
                    with the edge
                </Typography>
                <img src={cheetha} className={classes.cheetha} />
            </Grid>
        </Grid>
    )
}
