import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Divider, Grid, Icon, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, alpha } from '@mui/material';
import { useTheme } from '@mui/styles';
import { CameraOutlined, WorkspacesTwoTone, CloudSyncOutlined, GraphicEqOutlined, GridView, MovieOutlined, PlayCircleOutlineRounded, ShieldOutlined, CheckCircle, CheckCircleOutline, Close, ContactSupportOutlined, NewReleases, NewReleasesOutlined, HighlightOff, Check, CheckRounded, CloseRounded, InfoRounded, CheckBox, TaskAltOutlined, CancelOutlined } from '@mui/icons-material';

import moment from 'moment';
import { DASHBOARD_THUMBNAILS, H5LIVE_ABR, H5LIVE_DOCS, H5LIVE_SECURITY, H5LIVE_VOD, NANO_SUPPORT, OPCODES, WEBCASTER_GETTING_STARTED } from '../../utils/helper/link-config';
import { LIVE_PROCESSING } from '../../utils/helper/nanostream-cloud';

export default function EnabledOptions(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { orga } = props;

    const [pack, setPack] = useState([]);

    const handleOpenSupport = () => {
        let supportUrl = `${NANO_SUPPORT}?bintu.id=${orga?.id}&bintu.name=${orga?.name}&bintu.webhook=${orga?.webhook}&bintu.vod=${orga?.vod}&bintu.webrtc=${orga?.webrtc}&bintu.transcoding=${orga?.transcoding}&bintu.h5live=${orga?.h5live}&bintu.hash=${orga?.hash}&bintu.hls=${orga?.hls}&bintu.secure=${orga?.secure}&bintu.metrics=${orga?.metrics}&bintu.sla=${orga?.sla}&bintu.trial=${orga?.trial}`
        window.open(supportUrl, "_blank");
    }

    const handleOpenInNew = (link) => () => {
        window.open(link, "_target")
    }

    useEffect(() => {
        if (orga) {
            setPack([
                { label: "H5Live", value: orga.h5live, icon: <PlayCircleOutlineRounded />, link: H5LIVE_DOCS },
                { label: "Webcaster", value: orga.webrtc, icon: <CameraOutlined />, link: WEBCASTER_GETTING_STARTED },
                { label: "Transcoding", value: orga.transcoding, icon: <GraphicEqOutlined />, link: H5LIVE_ABR },
                // { label: "Live Processing", value: orga.allowedOpcodes?.length > 0, icon: <CloudSyncOutlined />, link: "" },
                { label: "Secure", value: orga.secure, icon: <ShieldOutlined />, link: H5LIVE_SECURITY },
                { label: "VOD", value: orga.vod, icon: <MovieOutlined />, link: H5LIVE_VOD },
                { label: "Metadata", value: orga.metadata, icon: <CloudSyncOutlined />, link: DASHBOARD_THUMBNAILS },
            ])
        }
    }, [orga])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ bgcolor: alpha(theme.palette.primary.main, 0.2), p: 0.7, borderRadius: theme.spacing(2) }}>
                    <Box p={2} sx={{ borderRadius: theme.spacing(2), border: `1.5px solid ${theme.palette.primary.main}`, bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900] }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography variant="h4" color="primary">
                                        nanoStream Cloud
                                    </Typography>
                                    <Chip
                                        size="small"
                                        color="info"
                                        variant="contained"
                                        label="Your package"
                                        sx={{
                                            backgroundColor: alpha(theme.palette.info.main, 0.2),
                                            color: theme.palette.info.main,
                                            borderRadius: theme.spacing(.5), fontWeight: 600
                                        }}
                                    />
                                </Stack>
                                <Typography variant="subtitle2" color="textSecondary">
                                    You are streaming with <b>nanoStream Cloud</b> since <i>{moment(orga.created_at).utc().format('MMMM Do, YYYY')}</i>.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={orga?.allowedOpcodes?.length > 0 ? 6 : 12}>
                                {
                                    pack
                                        .sort((a, b) => b.value - a.value)
                                        .map((p, i) => (
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                {p.value ? <CheckRounded sx={{ fontSize: 16 }} color="primary" /> : <CloseRounded sx={{ fontSize: 16, color: theme.palette.text.secondary }} />}
                                                <Typography variant="subtitle1" color={p.value ? "inherit" : "textSecondary"}>
                                                    {p.label}
                                                </Typography>
                                                <IconButton size="small" onClick={handleOpenInNew(p.link)}>
                                                    <InfoRounded fontSize="inherit" sx={{ color: alpha(theme.palette.text.secondary, 0.2) }} />
                                                </IconButton>
                                            </Stack>
                                        ))
                                }
                            </Grid>
                            {
                                orga?.allowedOpcodes?.length > 0 &&
                                <Grid item xs={12} md={6}>
                                    <Box sx={{
                                        py: 2, background: `linear-gradient(${theme.palette.primary.light}, ${theme.palette.primary.main});`,
                                        borderRadius: theme.spacing(2)
                                    }}>
                                        <Stack direction="row" spacing={.5} alignItems="center"
                                            sx={{
                                                py: 2, pl: 2, pr: 3, display: 'inline-flex',
                                                borderTopRightRadius: theme.spacing(4), borderBottomRightRadius: theme.spacing(4),
                                                bgcolor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900]
                                            }}
                                        >
                                            <Typography
                                                color="primary" variant="body1"
                                                sx={{ fontWeight: 700, lineHeight: 1, }}
                                            >
                                                Live Processing
                                            </Typography>
                                            <IconButton size="small" onClick={handleOpenInNew(OPCODES)}>
                                                <InfoRounded fontSize="inherit" sx={{ color: alpha(theme.palette.text.secondary, 0.1) }} />
                                            </IconButton>
                                        </Stack>
                                        <Box p={2}>
                                            <Stack direction="row" useFlexGap flexWrap={"wrap"} spacing={1}>
                                                {
                                                    Object.values(LIVE_PROCESSING).map((p, i) => {
                                                        let isEnabled = orga?.allowedOpcodes?.includes(p.id);
                                                        return (
                                                            <Chip
                                                                key={`${p.id}-${i}`} disabled={!isEnabled} color="secondary"
                                                                label={p.title} sx={isEnabled && { color: theme.palette.common.white, borderColor: theme.palette.common.white }}
                                                                icon={isEnabled ? <CheckRounded sx={{ color: `${theme.palette.common.white} !important` }} /> : <CloseRounded />}
                                                                variant="outlined" size="small"
                                                            />
                                                        )
                                                    })
                                                }
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}
